import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { ReactComponent as IconTimes } from "../../../../assets/icon-close.svg"
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { ACL } from '../../../../constants';
import { STATUS_CANCELED, STATUS_FUTURE } from '../../../pro/Payment';
import { dayjs, getActiveSinceForProduct, getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, showProOrUpgradeToProComponent, upgradeProSubscription } from '../../../../utils';

import VSEmpty from '../../../../components/vsEmpty/VSEmpty';
import VSList from '../../../../components/vsList/VSList';
import SubscriptionView from '../../../pro/SubscriptionView';
import VirtualAssistantLegacyPlanCard from '../../../virtualAssistant/VirtualAssistantLegacyPlanCard';

import './ClinicSettingSubscription.scss'
const ClinicSettingSubscription = props => {
    const {
        user,
        clinic,
        history,
		features,
        permissions
    } = props

    const location = useLocation()

    const rowsPerPage = 25

    const [ loading, setLoading ] = useState(false)
    const [ activeSubscription, setActiveSubscription ] = useState(null)

    const filter = useRef(null)

    const getSubscriptions = (page) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            if (features?.object) {
                Parse.Cloud.run("subscriptions", {
                    clinicId: clinic.objectId,
                    page: page
                })
                .then(response => {
                    const {
                        subscriptions,
                        total
                    } = response

                    setActiveSubscription(subscriptions.some(s => s.status != STATUS_CANCELED))
                    setLoading(false)

                    // TODO: what is de product?
                    if (features?.pro && getTrialEndsAtForProduct(features) && !features?.subscription) {
                        return resolve({
                            items: [
                                ...subscriptions,
                                {
                                    id: null,
                                    status: STATUS_FUTURE,
                                    next_billing_at: dayjs(getTrialEndsAtForProduct(features)).add(1, 'days').toDate(),
                                    plan: {
                                        name: "Prontuário Pro — Mensal"
                                    },
                                    current_period: {
                                        start_at: getActiveSinceForProduct(features),
                                        end_at: getTrialEndsAtForProduct(features)
                                    }
                                }
                            ],
                            total: total + 1
                        })
                    } else {
                        resolve({
                            items: subscriptions,
                            total: total
                        })
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        'Ocorreu algum erro ao buscar suas assinaturas, tente novamente mais tarde',
                        'error'
                    )
                    reject()
                })
            } else {
                setLoading(false)
                resolve({
                    items: [],
                    total: 0
                })
            }
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.ADMIN, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        onChange()
    }, [ features ])


    useEffect(() => {
        document.addEventListener("Subscription__getList", onChange)

        saveOnTracker("Acessou", "Gerenciar assinaturas", clinic.objectId, null, location?.state?.tag)

        return () => {
            document.removeEventListener("Subscription__getList", onChange)
        }
    }, [])

    return (
        <div id="clinic-setting-subscription">
            <div className="row">
                <div className="col-12">
                    <VSList
                        ref={filter}
                        pagination={{
                            rowsPerPage: rowsPerPage,
                            append: !loading && !activeSubscription && (
                                <button className="btn-main-mobile btn-submit btn-square w-100" onClick={() => redirectToCheckout(clinic)}>
                                    { !getTrialEndsAtForProduct(features) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                                </button>
                            )
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return getSubscriptions(_page)
                        }}
                        renderItem={(item, expand, index) => (
                            <>
                                {index == 0 && features?.isLegacy && clinic.isOwner &&
                                <VirtualAssistantLegacyPlanCard history={history} />}
                                <SubscriptionView subscription={item} {...props} />
                            </>
                        )}
                        renderEmpty={() => showProOrUpgradeToProComponent({
                            ...props,
                            ...upgradeProSubscription,
                            component: (
                                <VSEmpty
                                    icon={<IconTimes width="36px" height="36px" />}
                                    title="Você ainda não possui nenhuma assinatura no Prontuário Vet Smart"
                                >
                                    { !features?.pro && permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => redirectToCheckout(clinic)}
                                        >
                                            <IconAdd width="14" height="14" className="me-2" />
                                            Assinar
                                        </button>
                                    )}
                                </VSEmpty>
                            )
                        })}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions,
        features: state.features
    }
}

export default connect(mapStateToProps)(ClinicSettingSubscription)