import React, { useEffect } from 'react';
import { useLocation } from "react-router"
import { connect } from 'react-redux'

import * as actions from "../../store/actions"

window.previousURL = "/inicio"

import './Login.scss';
const Login = (props) => {

	const {
		user,
		clinic,
		history,
		onLoading
	} = props

	const { pathname } = new URL(location.href)
	const {
		state,
		search,
		hash
	} = useLocation()

	const logIn = () => {
		if (Parse.User.current()) {
			User.init();
			props.dispatch(actions.logIn(Parse.User.current()))
			
			if(window.clarity){
				clarity("set", "userId", User.getUserId());
			}
		} else {
			logOut()
		}
	}

	const logOut = () => {
		User.logOut();
		// because cookie doesn't work on different ports, ex: localhost:3000, localhost:3001 and *.localhost:8888
		const localhostReturnSessionToken = location.hostname == "localhost" ? "&returnToken=true" : ""
		window.location.href = `${process.env.REACT_APP_ACCOUNT}/entrar?continue=${encodeURIComponent(window.location.href)}${localhostReturnSessionToken}`
	}

	const loginWithCookie = () => {
		User.loginWithSessionTokenByCookie(
			() => logIn(),
			() => {
				const currentUser = Parse.User.current()
				if (currentUser) {
					// do login again to set cookie
					loginWithSessionToken(currentUser.getSessionToken())
				} else {
					logOut()
				}
			}
		)
	}

	const loginWithSessionToken = (sessionToken) => {
		User.loginWithSessionToken(
			sessionToken, 
			() => logIn(),
			// if sessionToken doesn't work, tries loggin with cookie
			() => loginWithCookie()
		)
	}

	const checkUser = () => {
		const queryParams = new URLSearchParams(search)
		if (queryParams.has('token')) {
			const sessionToken = queryParams.get('token')
			queryParams.delete('token')
			history.replace({
				search: queryParams.toString(),
			})

			loginWithSessionToken(sessionToken)
		} else {
			loginWithCookie()
		}
	}

	const checkIsPro = () => {
		const query = new Parse.Query("MSSubscription");
		query.equalTo("pointer.object", clinic.object);
		query.include("products.object");
		query.include("plan");
		query.first()
		.then(_subscription => {
			props.dispatch(actions.getProntuarioPro(_subscription))
		})
		.catch(error => {
			console.error(error)
			Swal.fire(
				'Oops...',
				`Não foi possível verificar a clínica`,
				'error'
			)
			onLoading(false)
		})
	}

	const setWindowHeight = () => {
		document.documentElement.style.setProperty('--vsprontuario-window-height', `${window.innerHeight}px`);
	}

	useEffect(() => {
		if (user?.object) {
			if (clinic && clinic.isLoaded && clinic.object) {
				checkIsPro()
			} else {
				if (pathname != "/selecionar-clinica") {
					history.push("/selecionar-clinica", {
						...state,
						fromURL: pathname + search + hash
					})
				}
			}
			onLoading(false)
		} else {
			checkUser()
		}
	}, [ user ]);

	useEffect(() => {
		window.addEventListener("resize", setWindowHeight)
		setWindowHeight()
		return () => {
			window.removeEventListener("resize", setWindowHeight)
		}
	}, [])

	return <></>
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(Login);
