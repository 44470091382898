
import * as constants from "../constants"

export const logIn = (user) => ({
    type: constants.API_LOGIN,
    payload: user
})

export const logOut = (user) => ({
    type: constants.API_LOGOUT,
    payload: user
})

export const getUser = (user) => ({
    type: constants.API_USER,
    payload: user
})

export const getSelectedClinicUser = (clinicUser) => ({
    type: constants.API_CLINIC_USER,
    payload: clinicUser
})

export const getOnboarding = (onboarding) => ({
    type: constants.API_ONBOARDING,
    payload: onboarding
})

export const getProntuarioPro = (subscription) => ({
    type: constants.API_PRO,
    payload: subscription
})

export const setShowingTag = (isShowing) => ({
    type: constants.API_TAG,
    payload: isShowing
})