import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { connect } from "react-redux"
import axios from "axios"

import { CircularProgress } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconCalendar } from "../../../../assets/icon-calendar.svg"
import { ReactComponent as IconDocument } from "../../../../assets/icon-document.svg"
import { ReactComponent as IconTerm } from "../../../../assets/icon-term.svg"

import { canUserEditVeterinaryDocuments, dayjs, saveOnTracker, stringifyArray } from "../../../../utils"
import { ACL } from "../../../../constants"
import { showTerm } from "./Term"

import VSMessage, { saveOnTrackerMessage } from "../../../../components/vsMessage/VSMessage";
import VSShare from "../../../../components/vsShare/VSShare"

import "./TermShare.scss"
const TermShare = (props) => {

    const {
        id_documento
    } = useParams()

    const {
        features,
        user,
        clinic,
        history,
        location,
        permissions
    } = props

    const MessageForm = useRef(null)

    const canEdit = canUserEditVeterinaryDocuments()

    const [ prefs, setPrefs ] = useState(null)

    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ errors, setErrors ] = useState([])

    const [ loading, setLoading ] = useState(false)
    const [ loadingPreview, setLoadingPreview ] = useState(true)
    const [ document, setDocument ] = useState(null)
    const [ preview, setPreview ] = useState(null)
    const [ previewType, setPreviewType ] = useState(1)

    const link = `${process.env.REACT_APP_PRESCRIPTION}/t/${document?.id}`
    const link_pdf = `${process.env.REACT_APP_PDF}/api/v2/t/${document?.id}`

    if (!permissions?.check(ACL.TERM, ACL.Rule.VIEW)) {
        permissions?.error()
        return <div></div>
    }

    const validate = () => {
        let _errors = []
        if (MessageForm.current) {
            _errors = MessageForm.current?.validate()
        }
        setErrors(_errors)
        return _errors
    }

    const send = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (errors.length == 0) {
                const values = MessageForm.current?.save()

                saveOnTrackerMessage("Atestado ou Termo", document.id, values)
            } else {
                reject()
            }
        })
    }

    const submitText = () => {
        let text = []
        if (!prefs) {
            text.push("Buscando informações")
        } else {
            if (prefs.get("printEnabled")) {
                text.push("Imprimir")
            }
            if (prefs.get("downloadEnabled") && prefs.get("sendToDownloadEnabled")) {
                text.push("Baixar")
            }
            const isSendDownload = prefs.get("downloadEnabled") && (prefs.get("sendToSMSEnabled") || prefs.get("sendToWhatsappEnabled") || prefs.get("sendToEmailEnabled"))
            if (prefs.get("whatsappEnabled") || prefs.get("smsEnabled") || prefs.get("emailEnabled") || isSendDownload) {
                text.push("Enviar")
            }
        }
        if (text.length == 0) {
            return "Escolha pelo menos uma opção"
        }
        return stringifyArray(text)
    }

    const getPreview = () => {
        setLoadingPreview(true)
        setPreview(null)

        axios.get(`${link}?donttrack&onlyContent`)
        .then(response => {
            setPreview(response.data)
            setLoadingPreview(false)
        })
    }

    const getMedicalRecordPrefs = () => {
        const query = new Parse.Query("MedicalRecordsPrefs");
        query.equalTo("clinic", clinic.object);
        query.equalTo("user", user.object);
        query.first()
        .then(_prefs => {
            if (_prefs) {
                setPrefs(_prefs)
            } else {
                const newPrefs = new Parse.Object("MedicalRecordsPrefs")
                newPrefs.setACL(clinic.ACL)
                newPrefs.set("clinic", clinic.object)
                newPrefs.set("user", user.object)
                newPrefs.set("printEnabled", false)
                newPrefs.set("printTermEnabled", false)
                newPrefs.set("sendToSMSEnabled", false)
                newPrefs.set("sendToWhatsappEnabled", false)
                newPrefs.set("sendToEmailEnabled", false)
                newPrefs.set("sendToDownloadEnabled", false)
                newPrefs.set("downloadEnabled", false)
                newPrefs.set("downloadTermEnabled", false)
                newPrefs.set("printColorType", 0)
                newPrefs.set("displayOld", false)
                newPrefs.set("disableToggle", false)
                newPrefs.save()
                .then(_prefs => {
                    setPrefs(_prefs)
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar cadastrar suas preferências de exibição e envio`,
                        'error'
                    )
                    newPrefs.revert()
                })
            }
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                `Ocorreu um erro ao gravar suas preferências, por favor tente novamente.`,
                'error'
            )
        })
    }

    useEffect(() => {
        if (clinic && clinic.object && user && user.object && id_documento) {
            setLoading(true)

            getMedicalRecordPrefs()

            var query = new Parse.Query("Term");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.include("patient");
            query.include("patient.owner");
            query.get(id_documento)
            .then(_document => {
                if (_document) {
                    setDocument(_document)
                } else {
                    console.error(_document)
                    Swal.fire(
                        'Desculpe',
                        `Não encontramos o documento informado`,
                        'error'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar buscar o documento`,
                    'error'
                )
                setLoading(false)
            })
        }
    }, [ clinic ])

    useEffect(() => {
        if (document) {
            getPreview()
        }
    }, [ document, location ])

    const patient = document?.get("patient")
    const owner = patient?.get("owner")

    return (
        <div id="term-share">
            <VSShare
                loading={loading}
                blocked={!canEdit}
                title={`Novo Atestado ou Termo`}
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="title">Salvar e Visualizar Atestado ou Termo</div>
                        </div>
                    </div>
                )}
                close="Fechar"
                onClose={() => history.goBack()}
                summary={(
                    <>
                        <div className="row g-3 align-items-center">
                            <div className="col-12">
                                <button className="btn-link text-start" onClick={() => history.push(`/animal/${patient.id}`)}>
                                    <IconArrowLeft width="8px" height="12px" />
                                    Voltar para o perfil do animal
                                </button>
                            </div>
                            <div className="col-12">
                                <h2 className="title">
                                    1 - Configure e Visualize seu Documento
                                </h2>
                            </div>
                            <div className="col">
                                <h4 className="subtitle">Resumo do Atestado ou Termo</h4>
                            </div>
                            <div className="col-auto">
                                <button className="btn-link" onClick={() => history.push(`/animal/${patient.id}/atestado_termo/${id_documento}`)}>
                                    <IconEdit width="18px" height="18px" />
                                    Editar <span className="d-none d-lg-inline-block">Atestado ou Termo</span>
                                </button>
                            </div>
                            <div className="col-7">
                                <div className="label_icon">
                                    <IconTerm height="24" width="24" />
                                    <span>{ document && showTerm(document) }</span>
                                </div>
                            </div>
                            <div className="col-5 text-end">
                                <div className="label_icon">
                                    <IconCalendar height="24" width="24" />
                                    <span>{ dayjs(document && document.get("documentDate")).format("DD/MM/YYYY") }</span>
                                </div>
                            </div>
                            <div className="col-12 label-info">
                                Documentos Digitais Veterinários (termos e atestados) ainda estão no processo de serem regulamentos pelos órgãos competentes. Por essa razão <b>todos os documentos precisam ser impressos e assinados</b>.
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h4 className="subtitle">Configurações e layout</h4>
                            </div>
                            <div className="col-auto">
                                <button className="btn-link p-0" onClick={() => {
                                    history.push(`/clinica`)
                                    saveOnTracker("Acessou", "Clínica", "", "", "Atestado ou Termo", document.id, "", "")
                                }}>
                                    <IconDocument width="24" height="24" />
                                    Configurar
                                </button>
                            </div>
                        </div>
                        <hr className="my-3 d-none d-md-block" />
                        <h4 className="subtitle mb-3 d-none d-md-block">
                            Prévia do documento (apenas disponível para impressão)
                        </h4>
                    </>
                )}
                preview={(
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="preview">
                                    { loadingPreview ? (
                                        <div style={{ textAlign: "center", padding: '36px' }}>
                                            <CircularProgress size={28} thickness={2} color="primary"/>
                                        </div>
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{__html: preview}}
                                            className={'document-print'}
                                        >
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                message={(
                    <>
                        { prefs && (
                            <VSMessage
                                ref={MessageForm}
                                features={features}
                                clinic={clinic.object}
                                history={history}
                                title="2 - Selecione o que você deseja fazer ao emitir o documento"
                                link={{
                                    url: link,
                                    urlToPDF: link_pdf,
                                }}
                                onChange={() => {
                                    validate()
                                }}
                                messages={[
                                    {
                                        title: "Imprimir Documento",
                                        checked: prefs?.get("printTermEnabled") || false,
                                        value: prefs?.get("printColorType") || 0,
                                        messageTypeId: "PRINT",
                                        onChange: (printColorType) => {
                                            prefs?.set("printColorType", printColorType)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                        onChecked: (checked) => {
                                            prefs?.set("printTermEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                    },
                                    {
                                        title: "Gerar Documento em PDF",
                                        checked: prefs?.get("downloadTermEnabled") || false,
                                        messageTypeId: "DOWNLOAD",
                                        onChecked: (checked) => {
                                            prefs?.set("downloadTermEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        }
                                    }
                                ]}
                                submitButton={(
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="btn-sticky">
                                                <button 
                                                    className="btn-submit w-100"
                                                    disabled={errors.length != 0}
                                                    onClick={() => send()}
                                                >
                                                    { submitText() }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        )}
                    </>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(TermShare)