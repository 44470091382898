import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { connect } from "react-redux"
import { dayjs, getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from "../../utils"
import { ACL } from "../../constants"
import { PRO_MONTHLY } from "../pro/Payment"
import { setShowingTag } from "../../store/actions"

import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"
import { ReactComponent as IconWarningTriangle } from "../../assets/icon-warning-triangle.svg"

import "./MaintenanceTag.scss"
const MaintenanceTag = (props) => {
	const [ ProntuarioUnderMaintenanceTag, setProntuarioUnderMaintenanceTag ] = useState(false)
	const [ ProntuarioUnderMaintenanceTagMessage, setProntuarioUnderMaintenanceTagMessage ] = useState("")
    useEffect(() => {
		Parse.Config.get().then(config => {
			setProntuarioUnderMaintenanceTag(Boolean(config.get("ProntuarioUnderMaintenanceTag")))
			setProntuarioUnderMaintenanceTagMessage(config.get("ProntuarioUnderMaintenanceTagMessage"))
		})
    }, [])

    if(!ProntuarioUnderMaintenanceTag){
        return <></>
    }

    return (
        <div id="maintenance-tag" className="tag-warning">
            <div className="row align-items-center g-3">
                <div className="col-12 col-md-auto">
                    <IconWarningTriangle width={36} height={36} fill="#FF8A00" />
                </div>
                <div className="col-12 col-md" dangerouslySetInnerHTML={{__html: ProntuarioUnderMaintenanceTagMessage}} />
                <div className="col-auto trial-pro-close">
                    <IconCloseO width={24} height={24} fill="#666666" className="cursor-pointer" onClick={() => setProntuarioUnderMaintenanceTag(false)} />
                </div>
            </div>
        </div>
    )
}
export default MaintenanceTag