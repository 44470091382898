import React from 'react'

import { ReactComponent as IconIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconIAService } from "../../assets/icon-ia-service.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"

import { redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from '../../utils'

import VSDrawerInfo from '../../components/vsDrawer/VSDrawerInfo';

import './VirtualAssistantDrawerInfoFreePreview.scss'
const VirtualAssistantDrawerInfoLegacyPreview = (props) => {

    const {
        clinic,
        features,
        history,
        hasDrawerInfoForAI,
        setHasDrawerInfoForAI,
        drawerInfoRef
    } = props
    
    if(!hasDrawerInfoForAI){
        return <></>
    }

    const title = (
        <>
            <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
            Assistente Vetsmart
        </>
    )

    const onClose = _ => {
        setHasDrawerInfoForAI(false)
    }

    return (
        <VSDrawerInfo close={title} ref={drawerInfoRef} onClose={() => setHasDrawerInfoForAI(false)}>
            <div className="vsdrawer-info-header d-none d-md-inline">
                {title}
                <button className="vsdrawer-info-close d-none d-md-inline" onClick={onClose}>
                    <IconClose fill="rgba(0, 0, 0, 0.8)" width="20" height="20" />
                </button>
            </div>
            <div id="virtual-assistant-drawer-info-free-preview" className="row flex-grow-1">
                <div className="col-12">
                    <div className="p-3">
                        <div className="text-center mx-auto mb-2">
                            <IconVirtualAssistantSelect className="mb-4" />
                            <h3 className="virtual-assistant-drawer-info-free-preview-title">Assistente Vetsmart</h3>
                        </div>
                        <div className="row justify-content-center mb-4">
                            <div className="col-auto">
                                <div className="feature-text">
                                    <IconIA className="me-2" width="20" height="20" fill="#FF8A00" />
                                    Prontuário PRO + Assistente Vetsmart
                                </div>
                                <div className="d-flex feature-text">
                                    <IconIAService className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                    Seus atendimentos ainda mais ágeis e eficientes
                                </div>
                            </div>
                        </div>
                        <p className="virtual-assistant-intro-text">Convidamos você, assinante PRO, a continuar utilizando o Assistente Vetsmart. Para manter essa funcionalidade, <b>faça o upgrade para o novo plano PRO que inclui o Assistente</b>. Com ele, você terá suporte digital para agilizar seus atendimentos, criando resumos clínicos rápidos e recebendo sugestões de diagnósticos.</p>
                        <p className="virtual-assistant-intro-text">Leve seu plano para um novo nível de eficiência!</p>
                        <div className="row mt-5">
                            <div className="col-12">
                                {features?.planCode == 'yearly' ? 
                                <div className="va-footer-text">Por apenas <b>+ R$96</b> à vista<br/>ou em até <b>12x de R$8</b></div> :
                                <div className="va-footer-text">Por apenas <b>+ R$10</b>/mês</div>}
                            </div>
                            {clinic?.isOwner && 
                            <div className="col-12">
                                <button className="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                                    // saveTrackersProSubscription(features)
                                    // redirectToCheckout(clinic)
                                    history.push('/configuracao-clinica/plano-e-cobranca/upgrade')
                                }}>
                                    Faça o Upgrade
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </VSDrawerInfo>
    )
}

export default VirtualAssistantDrawerInfoLegacyPreview