import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import './Blocked.scss'

import { ReactComponent as UserBlocked } from "../../assets/user-blocked.svg"
import { ReactComponent as UserBlockedCrmv } from "../../assets/user-blocked-crmv.svg"
import VetImage from "../../assets/vet.png"

const Blocked = (props) => {

    const {
        clinic,
        features
    } = props
    
    return (
        <React.Fragment>
            { clinic && !clinic.object ? (
                <div id="no-clinic">
                    <img src={VetImage} />
                </div>
            ) : (
                <div id="blocked">
                    <div className="row mb-3">
                        <div className="col">
                            <h4>Prontuário Vet Smart</h4>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <p>Para ter acesso a essa funcionalidade, você precisa:</p>
                        </div>
                    </div>
                    { features?.pro && (
                        <div className="card mb-3">
                            <div className="row justify-content-center m-0">
                                <div className="col-auto card-image">
                                    <svg width="38" height="38" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="18" cy="18" r="17" fill="white" stroke="#00C4B4" strokeWidth="2"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M23.714 27H22.2151C21.8847 27 21.7584 26.7483 21.711 26.6525L21.6788 26.554L21.5516 25.7621C21.4245 25.7057 21.3027 25.6358 21.18 25.5516L20.4145 25.8705C20.3178 25.9207 20.1718 25.9287 20.0411 25.8849C19.9077 25.841 19.7976 25.7478 19.7394 25.6296L18.9989 24.3451C18.9407 24.2269 18.9183 24.1113 18.9309 24.0029C18.9488 23.8363 19.041 23.702 19.1816 23.6312L19.7483 23.1699C19.7412 23.0902 19.7412 23.0168 19.7412 22.9523C19.7412 22.8833 19.7501 22.8152 19.76 22.7462L19.1198 22.233C18.8153 21.9311 18.9004 21.6723 18.9488 21.5737L19.7618 20.1433C20.0402 19.8656 20.2963 19.9507 20.3957 20.0018L21.1424 20.3126C21.2687 20.2284 21.4003 20.1558 21.5328 20.0895L21.6295 19.2503C21.7092 19.089 21.8883 18.9 22.1336 18.9H23.6334C23.9306 18.9 24.1733 19.1257 24.1733 19.4016L24.295 20.1388C24.4222 20.1952 24.544 20.266 24.6666 20.3502L25.4331 20.0304C25.5298 19.9803 25.6757 19.9722 25.8064 20.0161C25.9399 20.06 26.05 20.1531 26.1082 20.2714L26.8487 21.5558C26.9069 21.6741 26.9292 21.7896 26.9167 21.898C26.8988 22.0646 26.8066 22.1999 26.666 22.2706L26.0992 22.7319C26.1064 22.8089 26.1064 22.8824 26.1064 22.9496C26.1064 23.0149 26.0983 23.0804 26.0885 23.1457L26.7931 23.6599C26.8863 23.7011 26.9785 23.8354 26.9964 24.002C27.0089 24.1113 26.9874 24.2269 26.9382 24.3263L26.1798 25.6457C26.0661 25.875 25.742 25.9825 25.4931 25.8598L24.732 25.5427L24.7212 25.5499C24.6174 25.6179 24.5046 25.6914 24.3765 25.7559L24.2163 26.6525C24.1384 26.8137 23.9584 27 23.714 27ZM21.1862 22.9478C21.1862 21.975 21.9777 21.1841 22.95 21.1841C23.9206 21.1841 24.713 21.975 24.713 22.9478C24.713 23.9205 23.9224 24.7115 22.95 24.7115C21.9768 24.7115 21.1862 23.9205 21.1862 22.9478Z" fill="#00C4B4"/>
                                        <path d="M22.95 24.0253C22.3457 24.0253 21.872 23.5515 21.872 22.9487C21.872 22.345 22.3457 21.872 22.95 21.872C23.5535 21.872 24.0263 22.3459 24.0263 22.9487C24.0263 23.5515 23.5526 24.0253 22.95 24.0253Z" fill="#00C4B4"/>
                                        <path d="M17.8633 18.0876C20.4204 18.0876 22.5 16.0493 22.5 13.5438C22.5 11.0383 20.4204 9 17.8633 9C15.3072 9 13.2276 11.0383 13.2276 13.5438C13.2276 15.0805 14.0144 16.4359 15.2083 17.2579C11.6149 18.3581 9 22.531 9 26.3871V27H17.5042V26.999H17.5286C17.8741 26.999 18.1549 26.7237 18.1549 26.3851C18.1549 26.0465 17.8741 25.7712 17.5286 25.7712H16.3993C16.3944 25.7712 16.3905 25.7741 16.3866 25.7741H10.2761C10.5981 21.9794 13.8784 18.0876 17.8633 18.0876ZM14.4783 13.5429C14.4783 11.7136 15.9971 10.2249 17.8633 10.2249C19.7305 10.2249 21.2483 11.7136 21.2483 13.5429C21.2483 15.3721 19.7295 16.8608 17.8633 16.8608C15.9961 16.8608 14.4783 15.3731 14.4783 13.5429Z" fill="#00C4B4"/>
                                        <circle cx="17.775" cy="13.725" r="3.825" fill="#00C4B4"/>
                                    </svg>
                                </div>
                                <div className="col card-content">
                                    Ter o Perfil de Acesso ao prontuário de <b>Médico Veterinário</b>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="card mb-3">
                        <div className="row justify-content-center m-0">
                            <div className="col-auto card-image">
                                <UserBlocked width="38px" height="38px" />
                            </div>
                            <div className="col card-content">
                                Estar cadastrado no Vet Smart como <b>Médico Veterinário</b>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="row justify-content-center m-0">
                            <div className="col-auto card-image">
                                <UserBlockedCrmv width="36px" height="62px" />
                            </div>
                            <div className="col card-content">
                                Cadastrar o <b>CRMV</b> registrado no seu nome
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <a className="btn-submit w-100 d-block text-center" href={`${process.env.REACT_APP_ACCOUNT}`}>
                                editar dados de cadastro
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        features: state.features,
    };
};
  
export default connect(mapStateToProps)(Blocked);