import React from 'react'

import { getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from '../../utils';
import { ReactComponent as IconHomeAssistant } from "../../assets/icon-home-assistant.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"

import "../../components/cardProAdvertising/CardProAdvertising.scss"
const CardAssistant = (props) => {

    const {
        clinic,
        features,
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row g-3">
                    <div className="col-12 d-flex align-items-start justify-content-between">
                        <IconHomeAssistant style={{borderRadius: 10}} />
                        {/* <div className="new-tag">Novo</div> */}
                    </div>
                    <div className="col-12">
                        <div className="vsbox-title mb-1">
                            <IconHeaderIA className="me-0 me-lg-2" width="24px" height="24px" fill="#FF8A00" />
                            Assistente Vetsmart
                        </div>
                        <div className="mb-2">{!getTrialEndsAtForProduct(features, "prontuario_ai", "yearly") ? "Experimente" : "Assine"} o Assistente Vetsmart e tenha mais agilidade nos seus atendimentos!</div> 
                        <div className="mt-3">
                            <a 
                                style={{ 
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontWeight: 600
                                }} 
                                onClick={() => {
                                    saveTrackersProSubscription(features)
                                    redirectToCheckout(clinic, "prontuario_ai", "yearly")
                                }}
                            >
                                { !getTrialEndsAtForProduct(features, "prontuario_ai", "yearly") ? "Experimentar o Assistente 15 dias grátis" : "Assinar o Assistente Vetsmart" }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default CardAssistant;