import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { CircularProgress, MenuItem, TextField } from '@mui/material';

import { ReactComponent as IconExclamationO } from "../../assets/icon-danger-exclamation-o.svg"
import { ReactComponent as IconIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconIAService } from "../../assets/icon-ia-service.svg"
import { ReactComponent as IconFinancial } from "../../assets/icon-financial.svg"
import { ReactComponent as IconFeatures } from "../../assets/icon-features.svg"
import { ReactComponent as IconUserIA } from "../../assets/icon-user-ia.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"

import { addEvent, dayjs, formattedCurrency, mandatoryField, saveOnTracker } from '../../utils';
import { getProntuarioPro } from '../../store/actions';
import { BRAND_EMPTY, PRO_MONTHLY, PRO_MONTHLY_VALUE_BY_MONTH, PRO_YEARLY, PRO_YEARLY_BY_YEAR, STATUS_CANCELED, brandOptions, getBrand, getInstallmentsOfProntuarioProYearly } from './Payment';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import PaymentCardField from '../../components/paymentCardField/PaymentCardField';

import './ProUpgradeForm.scss'
const ProUpgradeForm = (props) => {

    const {
        features,
        user,
        clinic,
        history
    } = props

    const PaymentCardFieldRef = useRef(null)

    const [ subscription, setSubscription ] = useState(null)
    const [ showProrata, setShowProrata ] = useState(false)

    const [ loading, setLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const validateOnTouch = () => {
        if (hasSubmited) {
            // PaymentCardFieldRef?.current?.validateOnTouch()
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        if(PaymentCardFieldRef?.current?.validate && PaymentCardFieldRef?.current?.isEditField){
            isValid = PaymentCardFieldRef?.current?.validate()
        }

        return isValid
    }

    const isValid = () => (PaymentCardFieldRef?.current?.isValid && PaymentCardFieldRef?.current?.isValid()) || !PaymentCardFieldRef?.current?.isEditField

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a contratação")
        }
        
        if(PaymentCardFieldRef?.current?.fieldsWithErrorText){
            // errors = errors.concat(PaymentCardFieldRef?.current?.fieldsWithErrorText())
        }

        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate() && PaymentCardFieldRef?.current?.payment) {
                if (!loading) {
                    const payment = {
                        vindiId: PaymentCardFieldRef?.current?.isEditField ? null : PaymentCardFieldRef?.current?.payment?.id,
                        type: "credit_card",
                        brand: PaymentCardFieldRef?.current?.cardData?.brand,
                        number: PaymentCardFieldRef?.current?.cardData?.cardNumber,
                        name: PaymentCardFieldRef?.current?.cardData?.cardName,
                        expires: PaymentCardFieldRef?.current?.cardData?.cardExpires,
                        cvv: PaymentCardFieldRef?.current?.cardData?.cardCVC,
                        installments: features?.subscription?.installments ||  1
                    }

                    setLoading(true)
                    Parse.Cloud.run("subscribe", {
                        clinicId: clinic.objectId || clinic.id,
                        products: [
                            "prontuario_ai"
                        ],
                        plan: features?.planCode,
                        customer: {
                            vindiId: PaymentCardFieldRef?.current?.payment?.customer?.id
                        },
                        payment: payment
                    })
                    .then(_subscription => {
                        // const query = new Parse.Query("MSSubscription");
                        // query.equalTo("pointer.object", _subscription);
                        // query.include("products.object");
                        // query.include("plan");
                        // return query.first()
                        // .then(_subscription => {
                            props.dispatch(getProntuarioPro(_subscription))
                            addEvent("Subscription__getList", {})
                            setLoading(false)
                            return resolve(clinic)
                        // })
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: 'Desculpe',
                            text: error.code == 141 ? error.message : 'Não foi possível efetuar o pagamento, por favor, tente novamente',
                            footer: '<a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Fale conosco</a>'
                        })
                        return reject()
                    })
                    // resolve()
                }
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        PaymentCardFieldRef, 
        PaymentCardFieldRef?.current,
        PaymentCardFieldRef?.current?.cardData,
        PaymentCardFieldRef?.current?.cardData?.brand,
        PaymentCardFieldRef?.current?.cardData?.cardNumber,
        PaymentCardFieldRef?.current?.cardData?.cardName,
        PaymentCardFieldRef?.current?.cardData?.cardExpires,
        PaymentCardFieldRef?.current?.cardData?.cardCVC
    ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vspro-upgrade"
            width="80%"
            title="Upgrade de plano"
            cancel="Cancelar"
            submit={isValid ? "Upgrade de plano" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Upgrade de plano"
            }}
        >
            {loading ? (
                <div className="data-loading pt-5">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="row flex-column flex-sm-row p-3 vspro-upgrade-box g-0">
                                <div className="col">
                                    <div className="vspro-upgrade-title">
                                        Prontuário PRO + Assistente Vetsmart
                                        <IconIA className="ms-2" width="24" height="24" fill="#FF8A00" />
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconUserIA className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Acessos ilimitados
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconFeatures className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Todas as funcionalidades básicas de atendimento sem limitações
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconIAService className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        Recursos avançados de gestão para otimizar a rotina do seu estabelecimento
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconIA className="d-block me-2" width="20" height="20" fill="#FF8A00" />
                                        Resumo do histórico clínico e diagnóstico diferencial
                                    </div>
                                    <div className="feature-text d-flex">
                                        <IconFinancial className="d-block me-2" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                        <b>Por apenas + R$ 10/mês<sup>*</sup></b>
                                    </div>
                                </div>
                                <div className="col-auto d-flex flex-column align-items-center mt-3 mt-sm-0">
                                    <div className="vspro-upgrade-selected-plan">Plano Selecionado</div>
                                    {features?.planCode == 'yearly' ?
                                    <div className="plan-price">Em até 12x de<br/><span className="cost">R$47,90</span> /mês<br/>ou à vista R$ 574,80/ano</div> :
                                    <div className="plan-price">Por apenas<br/><span className="cost">R$59,90</span> /mês</div>}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <div>* Valor sujeito a cálculo pro-rata. <button className="btn-link p-0" onClick={() => setShowProrata(true)}>Saiba mais</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-5">
                            <div className="vspro-upgrade-subtitle">FORMA DE PAGAMENTO</div>
                            <div className="vspro-upgrade-text mt-1">A cobrança será feita no cartão cadastrado</div>
                            <PaymentCardField 
                                ref={PaymentCardFieldRef}
                                clinic={clinic}
                                features={features}
                                subscription={subscription}
                            />
                        </div>
                    </div>
                    {showProrata &&
                    <div className="prorata-container">
                        <button className="btn-link p-0" onClick={() => setShowProrata(false)}>
                            <IconArrowRight width={16} height={16} style={{ transform: "rotate(180deg)" }} />
                            Voltar
                        </button>
                        <div className="prorata-title">Como funciona o ajuste de valor (pro-rata) ao fazer um upgrade de plano?</div>
                        <div className="prorata-text">Quando você faz o upgrade de um plano para outro com valor mais alto, seja ele <b>mensal</b> ou <b>anual</b>, aplicamos o cálculo pro-rata para ajustar o valor de forma justa. Isso significa que você pagará proporcionalmente pelo tempo que usou o plano antigo e o tempo restante com o novo plano.</div>
                        <div className="prorata-subtitle">Veja um exemplo de ajuste no plano mensal:</div>
                        <div className="prorata-text">Imagine que você seja assinante do <b>Plano Prontuário PRO</b>, que custa <b>R$ 49,90/mês</b>, e decide fazer o upgrade para o novo plano <b>Plano Prontuário PRO + Assistente Vetsmart</b> que custa <b>R$ 59,90/mês</b>.</div>
                        <div className="prorata-text">Se o upgrade for feito no meio do mês, o cálculo será o seguinte:</div>
                        <div className="prorata-item"><b>Valor pelo tempo no plano antigo:</b> Você usou metade do mês com o plano de R$ 49,90. O valor proporcional por esse tempo será R$ 24,95 (metade do valor do plano).</div>
                        <div className="prorata-item"><b>Valor pelo tempo no novo plano:</b> Para os 15 dias restantes do mês, você usará o novo plano de R$ 59,90. O valor proporcional será R$ 29,95 (metade do valor do novo plano).</div>
                        <div className="prorata-item"><b>Total do mês ajustado:</b> Somando os dois valores, você pagará R$ 54,90 nesse mês, ao invés do valor cheio do novo plano (R$ 59,90). Nos meses seguintes, será cobrado o valor integral do novo plano, R$ 59,90/mês.</div>
                        <div className="prorata-subtitle">Agora veja um exemplo de ajuste no se for no plano anual:</div>
                        <div className="prorata-text">Suponha que você seja assinante do <b>Plano Prontuário PRO</b>, que custa <b>R$ 478,80/ano</b>, e decida fazer um upgrade para o plano <b>Prontuário PRO + Assistente Vetsmart</b> que custa <b>R$ 574,80/ano</b>, após 6 meses de uso. O cálculo será o seguinte:</div>
                        <div className="prorata-item"><b>Valor pelo tempo no plano antigo:</b> Como você já usou metade do ano com o plano atual, o valor proporcional será R$ 239,40 (metade do valor do plano anual).</div>
                        <div className="prorata-item"><b>Valor pelo tempo no novo plano:</b> Para os 6 meses restantes do ano, será calculado o valor proporcional do novo plano, que será R$ 287,40 (metade do valor do novo plano anual).</div>
                        <div className="prorata-item"><b>Total do ano ajustado:</b> O total que você vai pagar no ano será R$ 526,80, considerando os dois períodos (os 6 meses com o plano antigo e os 6 meses com o novo plano). Como você já pagou R$ 478,80 no início do ano, será cobrada a diferença de R$ 48,00 no momento do upgrade. No próximo ciclo, a renovação será pelo valor integral do novo plano, R$ 574,80/ano.</div>
                        <div className="prorata-subtitle">Conclusão:</div>
                        <div className="prorata-text pb-3">Esse ajuste pro-rata garante que você pague de forma justa pelo tempo que utilizou cada plano, sem ser cobrado integralmente pelo novo plano durante um período que você já pagou no plano anterior.</div>
                    </div>}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        features: state.features
    };
};

export default connect(mapStateToProps)(ProUpgradeForm)