import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import { ReactComponent as ClinicNew } from "../../assets/clinic-new.svg"
import { ReactComponent as IconCopy } from "../../assets/icon-copy.svg"
import { ReactComponent as IconExternalLink } from "../../assets/icon-external-link.svg"
import { ReactComponent as VetsmartLogo } from "../../assets/vetsmart-logo.svg"
import Certified from "../../assets/certified.png"

import VSRadio, { IconRadioChecked, IconRadioUnchecked } from '../vsRadio/VSRadio';
import TextFieldMask from '../textFieldMask/TextFieldMask';
import BoxDropdown from '../boxDropdown/BoxDropdown';
import VSPreview from './VSPreview';
import VSSend from './VSSend';

import { MESSAGE_PREVIEW_ERROR, getEmailPreview, getSmsPreview, getTrialEndsAtForProduct, getWhatsappPreview, redirectToCheckout, saveOnTracker, saveTrackersProSubscription, validateEmail, validatePhone } from '../../utils';
import { saveOwnerContactInfo } from "../../pages/owner/Owner";
import { ACL } from '../../constants';

export const saveOnTrackerMessage = (objectName, objectId, values) => {
    let data = []
    data.push(`[Imprimir:${values.print ? 1 : 0}]`)
    data.push(`[SMS:${values.sms ? 1 : 0}]`)
    data.push(`[WhatsApp:${values.whatsapp ? 1 : 0}]`)
    data.push(`[Email:${values.email ? 1 : 0}]`)
    data.push(`[Baixar:${values.download ? 1 : 0}]`)
    data.push(`[PDF_SMS:${values.pdfSMS ? 1 : 0}]`)
    data.push(`[PDF_WhatsApp:${values.pdfWhatsapp ? 1 : 0}]`)
    data.push(`[PDF_Email:${values.pdfEmail ? 1 : 0}]`)
    saveOnTracker(
        "Concluiu",
        objectName,
        objectId,
        "",
        objectName,
        objectId,
        "",
        data.join('')
    )
}

import './VSMessage.scss'
const VSMessage = forwardRef((props, ref) => {

    const {
        features,
        title,
        link,
        clinic,
        history,
        prependOwnerChild,
        owner,
        ownerSeparator = true,
        appendOwnerChild,
        showPersonalWhatsAppButton = true,
        messages,
        appendMessages,
        submitButton,
        onMessageLimitError,
        onMessageLimitAdvice,
        onChange,
        onResend,
        onSent,
        onShare,
        onClose,
        permissions
    } = props

    const { enqueueSnackbar } = useSnackbar();

    const [ sendOptions, setSendOptions ] = useState([])
    const [ selectedMessage, setSelectedMessage ] = useState([])
    const [ sending, setSending ] = useState(false)
    const [ limits, setLimits ] = useState([]) 

    const [ editPhone, setEditPhone ] = useState(false)
    const [ editEmail, setEditEmail ] = useState(false)

    const [ savingOwner, setSavingOwner ] = useState(false)

    // TextFields
    const [ errorOptionsSelected, setErrorOptionsSelected ] = useState(false)

    const [ phoneNumber, setPhoneNumber ] = useState(owner?.get("phone") || "")
    const [ errorPhoneNumber, setErrorPhoneNumber ] = useState(false)
    const helperPhoneNumber = "Preencha este campo para enviar"

    const [ emailAddress, setEmailAddress ] = useState(owner?.get("email") || "")
    const [ errorEmailAddress, setErrorEmailAddress ] = useState(false)
    const helperEmailAddress = "Preencha este campo para enviar"

    const [ whatsAppLink, setWhatsAppLink ] = useState()

    const limit = limits?.find(limit => limit.available <= 0)
    const limitTotal = limits?.length > 0 ? limits[0]?.total : 5 // default to 5

    // External Methods
    useImperativeHandle(ref, () => ({
        validate: validate,
        schedule: schedule,
        save: save,
        tag: getTag,
        limit: getLimit,
        adviceForLimit: getAdviceForLimit
    }));

    const validate = (errors = []) => {
        let selectedOption   = false
        let needPhoneNumber  = false
        let needEmailAddress = false
        sendOptions.map((option, index) => {
            if (option.checked) {
                var arrayMessages = option.messages || [option]
                if (!option.messages || selectedMessage[index]) {
                    selectedOption = true
                }
                arrayMessages.map(message => {
                    if (message.checked) {
                        if (messageTypeNeedsPhoneNumber(message.messageTypeId)) {
                            needPhoneNumber = true
                        }
                        if (messageTypeNeedsEmail(message.messageTypeId)) {
                            needEmailAddress = true
                        }
                    }
                })
            }
        })

        if (selectedOption) {
            if (needPhoneNumber && !validatePhone(phoneNumber)) {
                errors.push("Número de celular")
                setErrorPhoneNumber(true)
            } else {
                setErrorPhoneNumber(false)
            }

            if (needEmailAddress && !validateEmail(emailAddress)) {
                errors.push("E-mail")
                setErrorEmailAddress(true)
            } else {
                setErrorEmailAddress(false)
            }
        } else {
            errors.push("Nenhuma opção selecionada")
            setErrorOptionsSelected(true)
        }

        return errors
    }

    const getLimits = () => {
        var templates = []
        messages.map(option => {
            var _messages = []
            if (option.messages) {
                _messages = option.messages
            } else {
                _messages = [option]
            }
            _messages.map(message => {
                if (message.messageTemplateId) {
                    templates.push(message.messageTemplateId)
                }
            })
        })
        Parse.Cloud.run("checkMessageLimit", {
            clinicId: clinic.id,
            templates: templates
        })
        .then(({ limits }) => {
            setLimits(limits)
        })
        .catch(error => {
            console.error(error)
        })
    }

    const schedule = () => {
        var messagesToSchedule = []
        sendOptions.map(option => {
            const messages = option.messages || [option]
            messages.map(message => {
                if (message.checked)
                    messagesToSchedule.push(message)
            })
        })
        return messagesToSchedule
    }
 
    const save = () => {
        setSending(true)

        let values = {
            print: false,
            whatsapp: false,
            sms: false,
            email: false,
            download: false
        }

        sendOptions.map(options => {
            const _messages = options.messages || [options]
            _messages.map(message => {
                if (message.checked) {
                    switch (message.messageTypeId) {
                        case "PRINT":
                            values.print = true
                            break;
                        case "DOWNLOAD":
                            values.download = true
                            break;
                        case "WHATSAPP":
                            values.whatsapp = true
                            break;
                        case "SMS":
                            values.sms = true
                            break;
                        case "EMAIL":
                            values.email = true
                            break;
                    }
                }
            })
        })

        return values
    }

    const getTag = () => {
        if (limit) {
            return (
                <div id="trial-pro" className="trial trial-danger">
                    <div className="row align-items-center g-3">
                        <div className="col-auto">
                            <ClinicNew />
                        </div>
                        <div className="col">
                            <b>Você está usando o Prontuário Gratuito.</b>&nbsp;
                            { onMessageLimitError && onMessageLimitError(limit.total, true) }&nbsp;
                            { getTrialEndsAtForProduct(features) ? "Experimente" : "Assine" } o Prontuário PRO para utilizar envios ilimitados.
                        </div>
                        <div className="col-12 col-md-auto">
                            <a className="bordered-link text-center" onClick={onClickUpgradePro}>
                                { getTrialEndsAtForProduct(features) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else if (!features?.unlimited_messages) {
            return (
                <div id="trial-pro" className="trial trial-advice">
                    <div className="row align-items-center g-3">
                        <div className="col-auto">
                            <ClinicNew />
                        </div>
                        <div className="col">
                            <b>Você está usando o Prontuário Gratuito.</b>&nbsp;
                            { onMessageLimitAdvice && onMessageLimitAdvice(limitTotal, true) }&nbsp;
                            { getTrialEndsAtForProduct(features) ? "Experimente" : "Assine" } o Prontuário PRO para utilizar envios ilimitados.
                        </div>
                        <div className="col-12 col-md-auto">
                            <a className="bordered-link text-center" onClick={onClickUpgradePro}>
                                { getTrialEndsAtForProduct(features) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const getLimit = () => {
        return limit && (
            <div className="col-12">
                { onMessageLimitError && (
                    <p className="text-danger">
                        { onMessageLimitError(limit.total, false) }
                    </p>
                )}
                <div className="vsbox">
                    <div className="vsbox-body mb-0">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="vsbox-title">Você está usando o Prontuário gratuito</div>
                            </div>
                            <div className="col-auto">
                                <ClinicNew />
                            </div>
                            <div className="col">
                                { getTrialEndsAtForProduct(features) ? "Experimente" : "Assine" } o Prontuário PRO para utilizar envios e lembretes via WhatsApp, SMS ou E-mail ilimitados.
                            </div>
                            <div className="col-12">
                                <a 
                                    style={{ 
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        fontWeight: 600
                                    }} 
                                    onClick={onClickUpgradePro}
                                >
                                    { getTrialEndsAtForProduct(features) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getAdviceForLimit = () => {
        return !features?.unlimited_messages && (
            <div className="col-12 mb-4">
                <div className="vsbox">
                    <div className="vsbox-body mb-0">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="vsbox-title">Você está usando o Prontuário gratuito</div>
                            </div>
                            <div className="col-auto">
                                <ClinicNew />
                            </div>
                            <div className="col">
                            { onMessageLimitAdvice && onMessageLimitAdvice(limitTotal, true) } { getTrialEndsAtForProduct(features) ? "Experimente" : "Assine" } o Prontuário PRO para utilizar cadastros ilimitados.
                            </div>
                            <div className="col-12">
                                <a 
                                    style={{ 
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        fontWeight: 600
                                    }} 
                                    onClick={onClickUpgradePro}
                                >
                                    { getTrialEndsAtForProduct(features) ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // Methods
    const onCopy = () => {
        var text_to_copy = link.url

        if (!navigator.clipboard) {
            var tempInput = window.document.createElement("input")
            tempInput.value = text_to_copy
            window.document.body.appendChild(tempInput)
            tempInput.select()
            window.document.execCommand("copy")
            window.document.body.removeChild(tempInput)
    
            enqueueSnackbar("Link copiado com sucesso", { 
                variant: 'success',
            })
            link.onCopy()
        } else{
            navigator.clipboard.writeText(text_to_copy).then(() => {
                enqueueSnackbar("Link copiado com sucesso", { 
                    variant: 'success',
                })
                link.onCopy()
            })
            .catch(() => {
                enqueueSnackbar("Erro ao copiar o link", { 
                    variant: 'error',
                })
            });
        } 
    }

    const messageTypeNeedsPhoneNumber = (messageTypeId) => {
        return ["SMS", "WHATSAPP"].indexOf(messageTypeId) !== -1
    }

    const messageTypeNeedsEmail = (messageTypeId) => {
        return messageTypeId == "EMAIL"
    }

    const handleMessageChecked = (index, checked) => {
        let _sendOptions = [...sendOptions]
        _sendOptions[index].checked = checked
        setSendOptions(_sendOptions)
    }

    const onSaveOwner = () => {
        setSavingOwner(true)

        saveOwnerContactInfo(owner, phoneNumber, emailAddress, owner.get("cpf"))
        .then(_ => {
            setSavingOwner(false)
            setEditPhone(false)
            setEditEmail(false)
        })
        .catch(_ => {
            setSavingOwner(false)
        })
    }

    const ownerEditDataForm = (
        <div className={`owner-edit-data-form mb-3 pb-2 ${ownerSeparator ? "separator" : ""}`}>
            { prependOwnerChild }
            <div className="row g-2 align-items-center mb-2">
                <div className="col-12">
                    <div className="color-title mb-0">
                        Celular/WhatsApp:
                    </div>
                </div>
                { editPhone ? (
                    <>
                        <div className="col-12 col-lg">
                            <TextFieldMask
                                type="tel"
                                mask={phoneNumber?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                value={phoneNumber}
                                onAccept={(value) => setPhoneNumber(value)}
                                placeholder="(00) 00000-0000"
                                error={errorPhoneNumber}
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={savingOwner}
                            />
                        </div>
                        <div className="col-6 col-lg-auto">
                            <button
                                className="btn-submit btn-square w-100"
                                disabled={savingOwner}
                                onClick={() => onSaveOwner()}
                            >
                                { savingOwner ? (
                                    <CircularProgress size="12px" />
                                ) : (
                                    "Salvar"
                                )}
                            </button>
                        </div>
                        <div className="col-6 col-lg-auto">
                            <button
                                className="vsbox-btn vsbox-btn-primary w-100"
                                onClick={() => {
                                    setPhoneNumber(owner.get('phone'))
                                    setEditPhone(false)
                                }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col text-black">
                            { phoneNumber || "Não informado" }
                        </div>
                        <div className="col-auto">
                            <div
                                className="btn-preview"
                                onClick={() => setEditPhone(true)}
                            >
                                EDITAR
                            </div>
                        </div>
                    </>
                )}
                { errorPhoneNumber ? (
                    <div className="col-12">
                        <small className="text-danger">{ helperPhoneNumber }</small>
                    </div>
                ) : showPersonalWhatsAppButton && whatsAppLink && (
                    <div className="col-12">
                        <a
                            href={whatsAppLink}
                            target="_blank"
                            className="btn-outline-submit btn-square w-100 d-block"
                            style={{ textTransform: "none" }}
                            onClick={() => onShare && onShare()}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="me-2" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.05 4.91C17.18 3.03 14.69 2 12.04 2C6.58005 2 2.13005 6.45 2.13005 11.91C2.13005 13.66 2.59005 15.36 3.45005 16.86L2.05005 22L7.30005 20.62C8.75005 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.9501 17.38 21.9501 11.92C21.9501 9.27 20.92 6.78 19.05 4.91ZM12.04 20.15C10.56 20.15 9.11005 19.75 7.84005 19L7.54005 18.82L4.42005 19.64L5.25005 16.6L5.05005 16.29C4.23005 14.98 3.79005 13.46 3.79005 11.91C3.79005 7.37 7.49005 3.67 12.03 3.67C14.23 3.67 16.3 4.53 17.85 6.09C19.41 7.65 20.26 9.72 20.26 11.92C20.2801 16.46 16.58 20.15 12.04 20.15ZM16.56 13.99C16.31 13.87 15.09 13.27 14.87 13.18C14.64 13.1 14.48 13.06 14.31 13.3C14.14 13.55 13.67 14.11 13.53 14.27C13.39 14.44 13.24 14.46 12.99 14.33C12.74 14.21 11.94 13.94 11 13.1C10.26 12.44 9.77005 11.63 9.62005 11.38C9.48005 11.13 9.60005 11 9.73005 10.87C9.84005 10.76 9.98005 10.58 10.1 10.44C10.22 10.3 10.27 10.19 10.35 10.03C10.43 9.86 10.39 9.72 10.33 9.6C10.27 9.48 9.77005 8.26 9.57005 7.76C9.37005 7.28 9.16005 7.34 9.01005 7.33C8.86005 7.33 8.70005 7.33 8.53005 7.33C8.36005 7.33 8.10005 7.39 7.87005 7.64C7.65005 7.89 7.01005 8.49 7.01005 9.71C7.01005 10.93 7.90005 12.11 8.02005 12.27C8.14005 12.44 9.77005 14.94 12.25 16.01C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.69 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.03 14.27C16.96 14.16 16.81 14.11 16.56 13.99Z" fill="#00C4B4"/>
                            </svg>
                            Enviar Mensagem para Tutor(a)
                        </a>
                    </div>
                )}
            </div>
            <div className="row g-2 align-items-center mb-2">
                <div className="col-12">
                    <div className="color-title mb-0">
                        E-mail:
                    </div>
                </div>
                { editEmail ? (
                    <>
                        <div className="col-12 col-lg">
                            <TextField
                                type="email"
                                placeholder="exemplo@email.com"
                                value={emailAddress}
                                onChange={({ target: { value } }) => setEmailAddress(value)}
                                error={errorEmailAddress}
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={savingOwner}
                            />
                        </div>
                        <div className="col-6 col-lg-auto">
                            <button
                                className="btn-submit btn-square w-100"
                                disabled={savingOwner}
                                onClick={() => onSaveOwner()}
                            >
                                { savingOwner ? (
                                    <CircularProgress size="12px" />
                                ) : (
                                    "Salvar"
                                )}
                            </button>
                        </div>
                        <div className="col-6 col-lg-auto">
                            <button
                                className="vsbox-btn vsbox-btn-primary w-100"
                                onClick={() => {
                                    setEmailAddress(owner.get('email'))
                                    setEditEmail(false)
                                }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col text-black">
                            { emailAddress || "Não informado" }
                        </div>
                        <div className="col-auto">
                            <div
                                className="btn-preview"
                                onClick={() => setEditEmail(true)}
                            >
                                EDITAR
                            </div>
                        </div>
                    </>
                )}
                { errorEmailAddress && (
                    <div className="col-12">
                        <small className="text-danger">{ helperEmailAddress }</small>
                    </div>
                )}
            </div>
            { appendOwnerChild }
        </div>
    )

    const contactForm = (
        <div className="owner-edit-data-form pb-3 mb-3 separator">
            <div className="row g-3 align-items-center">
                <div className="col-12">
                    <div className="color-title text-uppercase">
                        Número de Contato
                    </div>
                </div>
                <div className="col-12">
                    A opção de <b>{permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object) ?
                    <a className="text-link d-inline text-decoration-none" href="/configuracao-clinica/informacoes-de-documentos#numero-contato" target="_blank">Incluir Número de Contato</a> :
                    <>Incluir Número de Contato</>}</b> está <b>{ clinic.get("phoneWhatsApp") ? "ATIVADA" : "DESATIVADA" }</b> no 
                    cadastro da clínica. Todas as mensagens enviadas via WhatsApp { clinic.get("phoneWhatsApp") ? "" : "não" } terão 
                    um botão para que o tutor entre contato diretamente com a clínica.
                </div>
                { clinic.get("phoneWhatsApp") && (
                    <div className="col-12">
                        Celular/WhatsApp da Clínica:
                        <b className="d-block mt-2">{ clinic.get("phoneWhatsApp") }</b>
                    </div>
                )}
            </div>
        </div>
    )

    const print = (index) => {
        const message = sendOptions[index]
        return (
            <div className="col-12">
                <BoxDropdown
                    title={message.title || "Imprimir Documento"}
                    checked={message.checked}
                    onChecked={(checked) => {
                        handleMessageChecked(index, checked)
                        message.onChecked && message.onChecked(checked)
                    }}
                >
                    <div className="row">
                        <div className="col">
                            <label className="input-label">CONFIGURAÇÃO DE COR</label>
                            <VSRadio
                                horizontal={true}
                                options={[
                                    {
                                        label: "Colorida",
                                        value: 0
                                    },
                                    {
                                        label: "Preto e Branco",
                                        value: 1
                                    }
                                ]}
                                value={message.value}
                                onChange={(_, value) => {
                                    const color = parseInt(value)
                                    let _options = [...sendOptions]
                                    _options[index].value = color
                                    setSendOptions(_options)
                                    message.onChange && message.onChange(color)
                                }}
                            />
                        </div>
                    </div>
                </BoxDropdown>
            </div>
        )
    }

    const download = (index) => {
        const message = sendOptions[index]
        return (
            <div className="col-12">
                <BoxDropdown
                    title={message.title || "Baixar Documento em PDF"}
                    checked={message.checked}
                    onChecked={(checked) => {
                        handleMessageChecked(index, checked)
                        message.onChecked && message.onChecked(checked)
                    }}
                />
            </div>
        )
    }

    const sendWhatsapp = (optionsIndex, sendIndex) => {
        const sendMessage = sendOptions[optionsIndex].messages[sendIndex]
        return (
            <>
                <div className="row">
                    <div className="col">
                        <FormControlLabel
                            label={sendMessage.title || "Enviar por WhatsApp"}
                            value={sendMessage.messageTemplateId}
                            checked={selectedMessage.indexOf(sendMessage.messageTemplateId) != -1}
                            control={
                                <Radio 
                                    color="primary"
                                    checkedIcon={IconRadioChecked()}
                                    icon={IconRadioUnchecked()}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <VSPreview
                            openedTitle="Fechar WhatsApp que será enviado"
                            closedTitle="Ver WhatsApp que será enviado"
                            onChange={(checked) => {
                                if (checked && !sendMessage.preview) {
                                    sendMessage.preview = "Buscando"
                                    getWhatsappPreview(
                                        sendMessage,
                                        sendMessage.messageTemplateId
                                    )
                                    .then(message => {
                                        let _sendOptions = [...sendOptions]
                                        _sendOptions[optionsIndex].messages[sendIndex].preview = message
                                        setSendOptions(_sendOptions)
                                    })
                                }
                            }}
                        >
                            <div className="box-vetsmart">
                                <VetsmartLogo width="35px" height="35px" style={{marginRight: '10px'}} />
                                Vetsmart
                                <img src={Certified} className="ms-2" width="13" height="13" />
                            </div>
                            { sendMessage.preview ? (
                                <>
                                    <div dangerouslySetInnerHTML={{
                                        __html: sendMessage.preview.message
                                    }} />
                                    { sendMessage.preview.CTA && (
                                        <>
                                            <hr className="my-3" />
                                            <div className="text-center">
                                                <a href={`https://wa.me/55${clinic.get("phoneWhatsApp").replace(/[^0-9]/g, "")}`} target="_blank" className="message-cta">
                                                    <IconExternalLink width={24} height={24} className="me-2" /> 
                                                    { sendMessage.preview.CTA }
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="data-loading">
                                    <CircularProgress color="primary" size={20} />
                                </div>
                            )}
                        </VSPreview>
                    </div>
                </div>
            </>
        )
    }

    const sendSMS = (optionsIndex, sendIndex) => {
        const sendMessage = sendOptions[optionsIndex].messages[sendIndex]
        return (
            <>
                <div className="row">
                    <div className="col">
                        <FormControlLabel
                            label={sendMessage.title || "Enviar por SMS"}
                            value={sendMessage.messageTemplateId}
                            checked={selectedMessage.indexOf(sendMessage.messageTemplateId) != -1}
                            control={
                                <Radio 
                                    color="primary"
                                    checkedIcon={IconRadioChecked()}
                                    icon={IconRadioUnchecked()}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <VSPreview
                            openedTitle="Fechar SMS que será enviado"
                            closedTitle="Ver SMS que será enviado"
                            onChange={(checked) => {
                                if (checked && !sendMessage.preview) {
                                    sendMessage.preview = "Buscando"
                                    getSmsPreview(
                                        sendMessage,
                                        sendMessage.messageTemplateId
                                    )
                                    .then(message => {
                                        let _sendOptions = [...sendOptions]
                                        _sendOptions[optionsIndex].messages[sendIndex].preview = message
                                        setSendOptions(_sendOptions)
                                    })
                                }
                            }}
                        >
                            { sendMessage.preview ? (
                                <div dangerouslySetInnerHTML={{
                                    __html: sendMessage.preview.message
                                }} />
                            ) : (
                                <div className="data-loading">
                                    <CircularProgress color="primary" size={20} />
                                </div>
                            )}
                        </VSPreview>
                    </div>
                </div>
            </>
        )
    }

    const sendEmail = (optionsIndex, sendIndex) => {
        const sendMessage = sendOptions[optionsIndex].messages[sendIndex]
        return (
            <>
                <div className="row">
                    <div className="col">
                        <FormControlLabel
                            label={sendMessage.title || "Enviar por E-mail"}
                            value={sendMessage.messageTemplateId}
                            checked={selectedMessage.indexOf(sendMessage.messageTemplateId) != -1}
                            control={
                                <Radio 
                                    color="primary"
                                    checkedIcon={IconRadioChecked()}
                                    icon={IconRadioUnchecked()}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <VSPreview
                            openedTitle="Fechar E-mail que será enviado"
                            closedTitle="Ver E-mail que será enviado"
                            onChange={(checked) => {
                                if (checked && !sendMessage.preview) {
                                    sendMessage.preview = "Buscando"
                                    getEmailPreview(
                                        sendMessage,
                                        sendMessage.messageTemplateId
                                    )
                                    .then(message => {
                                        let _sendOptions = [...sendOptions]
                                        _sendOptions[optionsIndex].messages[sendIndex].preview = message
                                        setSendOptions(_sendOptions)
                                    })
                                }
                            }}
                        >
                            <b>Enviado por:</b> vetsmart@vetsmart.com.br
                            <br/><br/>
                            { sendMessage.preview ? (
                                <div dangerouslySetInnerHTML={{
                                    __html: sendMessage.preview.message
                                }} />
                            ) : (
                                <div className="data-loading">
                                    <CircularProgress color="primary" size={20} />
                                </div>
                            )}
                        </VSPreview>
                    </div>
                </div>
            </>
        )
    }

    const sendArray = (index, title, messages) => {
        return messages.length > 0 && (
            <>
                { title && (
                    <div className="row">
                        <div className="col">
                            <label className="input-label">
                                { title }
                            </label>
                        </div>
                    </div>
                )}
                { messages.map((message, sendIndex) => (
                    <React.Fragment key={sendIndex}>
                        { message.messageTypeId == "WHATSAPP" ? (
                            sendWhatsapp(index, sendIndex)
                        ) : message.messageTypeId == "SMS" ? (
                            sendSMS(index, sendIndex)
                        ) : message.messageTypeId == "EMAIL" ? (
                            sendEmail(index, sendIndex)
                        ) : undefined }
                        { message.withSeparator && (<hr className="my-2" />)}
                    </React.Fragment>
                )) }
            </>
        )
    }

    const send = (index) => {
        const message = sendOptions[index]
        const sendMessages = message.messages || []
        return (
            <div className="col-12">
                { sendOptions.length > 1 ? (
                    <BoxDropdown
                        title={message.title || "Enviar Cópia Digital"}
                        checked={message.checked && !limit}
                        disabled={Boolean(limit)}
                        onChecked={(checked) => {
                            handleMessageChecked(index, checked)
                            message.onChecked && message.onChecked(checked)
                            if (!checked) {
                                var newSelectedMessage = [...selectedMessage]
                                newSelectedMessage[index] = ""
                                setSelectedMessage(newSelectedMessage)
                                message.messages.map(_message => {
                                    if (_message.checked) {
                                        _message.onChecked && _message.onChecked(checked)
                                    }
                                })
                            }
                        }}
                    >
                        { sendArray(index, message.text, sendMessages) }
                    </BoxDropdown>
                ) : (
                    sendArray(index, message.text, sendMessages)
                )}
            </div>
        )
    }

    const onClickUpgradePro = () => {
        saveTrackersProSubscription(features)
        redirectToCheckout(clinic)
    }

    const getWhatsAppMessage = () => {
        var foundWhatsAppTemplate = false
        if (Array.isArray(messages)) {
            messages.map((option, index) => {
                var _messages = option.messages || [option]
                _messages?.map((message, messageIndex) => {
                    if (message.messageTypeId == "WHATSAPP") {
                        foundWhatsAppTemplate = true
                        message.toHTML = false
                        getWhatsappPreview(
                            message,
                            message.messageTemplateId
                        )
                        .then(({ message }) => {
                            if (message !== MESSAGE_PREVIEW_ERROR) {
                                const sanitizedPhone = `55${phoneNumber.replace(/[^0-9]/g, "")}`
                                const previewWithoutEmojis = message?.replace(/(([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]) )/g, '')
                                const encodedMessage = encodeURIComponent(previewWithoutEmojis)
                                if (message && sanitizedPhone.length >= 12) {
                                    setWhatsAppLink(`https://wa.me/${sanitizedPhone}?text=${encodedMessage}`)
                                }
                            }
                        })
                    }
                })
            })
            if (!foundWhatsAppTemplate) {
                setSendOptions(messages)
            }
        }
    }

    useEffect(() => {
        if (limit) {
            setSelectedMessage([])
        }
    }, [ limit ])

    useEffect(() => {
        if (messages) {
            setSendOptions(messages)

            var newSelectedMessage = []
            messages.map((options, index) => {
                options.messages?.some(message => {
                    if (message.checked) {
                        newSelectedMessage[index] = message.messageTemplateId
                        return message.messageTemplateId
                    }
                })
            })
            setSelectedMessage(newSelectedMessage)

            getLimits()
        }
    }, [ JSON.stringify(messages) ])

    useEffect(() => {
        if (validatePhone(phoneNumber)) {
            getWhatsAppMessage()
        }
    }, [ phoneNumber ])

    useEffect(() => {
        if (owner) {
            setPhoneNumber(owner.get("phone") || phoneNumber)
            setEmailAddress(owner.get("email") || emailAddress)
        }
    }, [ owner ])

    useEffect(() => {
        onChange()
    }, [ sendOptions, phoneNumber, emailAddress, limits, selectedMessage ])

    return (
        <div id="vsmessage">
            { title && (
                <div className="row mb-2">
                    <div className="col">
                        <h2 className="title mb-2 pb-3 separator">{ title }</h2>
                    </div>
                </div>
            )}
            { owner && (
                <>
                    <div className="row align-items-center mb-2">
                        <div className="col-12">
                            <div className="color-title text-uppercase">
                                Dados de Contato do(a) Tutor(a)
                            </div>
                            <p className="color-info">
                                Lembre-se de sempre manter os dados de contato 
                                do(a) tutor(a) atualizados
                            </p>
                        </div>
                    </div>
                    {ownerEditDataForm}
                    {contactForm}
                </>
            )}
            <div className="row align-items-center mb-2">
                <div className="col-12">
                    <div className="color-title text-uppercase">
                        Opções de Envio
                    </div>
                </div>
            </div>
            <div className="row g-3 align-items-center">
                { sendOptions && sendOptions.map((message, index) => (
                    <React.Fragment key={index}>
                        { message.messageTypeId == "PRINT" ? (
                            print(index)
                        ) : message.messageTypeId == "DOWNLOAD" ? (
                            download(index)
                        ) : (
                            <RadioGroup
                                value={selectedMessage[index]}
                                onChange={({ target: { value } }) => {
                                    const newSelectedMessage = [...selectedMessage]
                                    newSelectedMessage[index] = value
                                    setSelectedMessage(newSelectedMessage)

                                    var _sendOptions = [...sendOptions]
                                    _sendOptions[index].messages.map(_message => {
                                        const currentChecked = _message.checked
                                        _message.checked = _message.messageTemplateId == value
                                        if (currentChecked != _message.checked) {
                                            _message.onChecked && _message.onChecked(_message.checked)
                                        }
                                    })
                                    setSendOptions(_sendOptions)
                                }}
                            >
                                { send(index) }
                            </RadioGroup>
                        )}
                    </React.Fragment>
                )) }
                { appendMessages }
            </div>
            { sending && (
                <VSSend
                    messages={sendOptions}
                    link={link}
                    owner={owner}
                    emailAddress={emailAddress}
                    phoneNumber={phoneNumber}
                    onClose={() => {
                        setSending(false)
                        getLimits()
                        onClose && onClose()
                    }}
                    onResend={() => {
                        setSending(false)
                        getLimits()
                        onResend && onResend()
                    }}
                    onSent={onSent}
                />
            )}
            {submitButton &&
            <div id="vsmessage-submit-button-wrap" className={`pb-4 my-4 ${link?.onCopy ? "separator" : ""}`}>
                {submitButton}
            </div>}
            { link?.onCopy && (
                <div id="document-copy" className="row mx-0 mt-3">
                    <div className="col col-md-12 px-0">
                        <div className="row align-items-center">
                            <div className="col-12 text-break pe-2">
                                { link.text }
                            </div>
                            <div className="col text-break pe-0">
                                <a href={link.url} target="_blank" onClick={() => link.onClick()}>
                                    { link.url }
                                </a>
                            </div>
                            <div className="col-auto">
                                <button onClick={() => onCopy()}>
                                    Copiar
                                    <IconCopy width="19px" height="22px" className="d-inline-block ms-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
})

VSMessage.propTypes = {
    title: PropTypes.string,
    link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
        urlToPDF: PropTypes.string,
        onClick: PropTypes.func,
        onCopy: PropTypes.func,
    }),
    prependOwnerChild: PropTypes.node,
    ownerSeparator: PropTypes.bool,
    appendOwnerChild: PropTypes.node,
    showPersonalWhatsAppButton: PropTypes.bool,
    messages: PropTypes.array,
    appendMessages: PropTypes.node,
    submitButton: PropTypes.node,
    onMessageLimitError: PropTypes.func,
    onChange: PropTypes.func,
    onResend: PropTypes.func,
    onSent: PropTypes.func,
    onClose: PropTypes.func
};

VSMessage.defaultProps = {
    title: "",
    link: {
        text: "",
        url: "",
        urlToPDF: "",
        onClick: null,
        onCopy: null
    },
    prependOwnerChild: null,
    ownerSeparator: true,
    appendOwnerChild: null,
    showPersonalWhatsAppButton: true,
    messages: [],
    appendMessages: null,
    submitButton: null,
    onMessageLimitError: (limitTotal, isShowingOnBanner) => null,
    onChange: () => null,
    onResend: () => null,
    onSent: (result) => null,
    onClose: () => null
}

const mapStateToProps = state => {
    return {
        features: state.features,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(VSMessage);