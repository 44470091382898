import React, { useState } from 'react'

import { ReactComponent as IconSimpleWarning } from "../../../../assets/icon-simple-warning.svg"
import { ReactComponent as IconIAGenerate } from "../../../../assets/icon-ia-generate.svg"
import { ReactComponent as IconPatientAppointment } from '../../../../assets/icon-patient-appointment.svg'

import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const AppointmentFormDifferentialDiagnosisPreview = (props) => {

    const {
        aiCache,
        hasDifferentialDiagnosisChanged,
        getDifferentialDiagnosis
    } = props

    const [ tab, setTab ] = useState("clinical")
    const response = aiCache?.get("response")
    if(!response || Object.keys(response).length <= 0){
        return (
            <VSEmpty
                icon={<IconPatientAppointment width="32px" height="32px" />}
                title="Esta consulta ainda não tem nenhuma informação para o diagnóstico diferencial"
                text="Escreva alguma informação sobre a consulta e tente novamente"
                className="differential-diagnosis-preview"
            >
            </VSEmpty>
        )
    }

    return (
        <div className="differential-diagnosis-preview row flex-column align-items-end mb-3 gx-0">
            <div className="col-12">
                <Tabs
                    className="vstabs px-3"
                    value={tab}
                    // onChange={(_, selected) => null}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab
                        value="clinical"
                        label="Manifestações Clínicas"
                        onClick={_ => setTab("clinical")}
                    />
                    <Tab
                        value="exams"
                        label="Exames Recomendados"
                        onClick={_ => setTab("exams")}
                    />
                    <Tab
                        value="treatment"
                        label="Tratamento e Manejo"
                        onClick={_ => setTab("treatment")}
                    />
                    <Tab
                        value="notes"
                        label="Observações Adicionais"
                        onClick={_ => setTab("notes")}
                    />
                    {/* <Tab
                        value="bibliography"
                        label="Fontes bibliográficas"
                        onClick={_ => setTab("bibliography")}
                    /> */}
                </Tabs>
            </div>
            <div className="col-12 ia-warning-text px-3 py-2">
                <IconSimpleWarning className="me-2" width="16" height="16" fill="#666666" />
                A IA pode produzir resultados imprecisos. <a className="link" href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/29652456409883-Assistente-Vetsmart" target="_blank">Saiba mais</a>.
            </div>
            <div className={`col-12 px-3 flex-grow-1 differential-diagnosis-wrap ${hasDifferentialDiagnosisChanged ? 'blur-elements' : ''}`}>
                {hasDifferentialDiagnosisChanged && (
                    <div className="differential-diagnosis-changed-box">
                        <div>Novas informações foram incluídas na Anamnese</div>
                        <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start`} onClick={() => {
                            getDifferentialDiagnosis()
                        }}>
                            <IconIAGenerate className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            Gerar Novo Resultado
                        </button>
                    </div>
                )}
                {tab == "clinical" ? (
                    response?.clinical?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.title}</div>
                            <div className="differential-diagnosis-text">{element?.text}</div>
                            <div className="differential-diagnosis-subtitle">Sintomas informados compatíveis:</div>
                            <div className="differential-diagnosis-text">{element?.symptoms}</div>
                            <div className="differential-diagnosis-subtitle">Outros sintomas não informados:</div>
                            <div className="differential-diagnosis-text">{element?.otherSymptoms}</div>
                        </div>
                    ))
                ) : tab == "exams" ? (
                    response?.exams?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.exam}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                ) : tab == "treatment" ? (
                    response?.treatment?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.treatment}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                ) : tab == "notes" ? (
                    response?.notes?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.note}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                )
                //  : tab == "bibliography" ? (
                //     response?.bibliography?.map((element, index) => element && (
                //         <div className="differential-diagnosis-element" key={index}>
                //             <div className="differential-diagnosis-text">{element?.name}</div>
                //             <a className="differential-diagnosis-link" target="_blank" href={element?.link}>{element?.link}</a>
                //         </div>
                //     ))
                // ) 
                : null}
            </div>
        </div>
    )
}

export default AppointmentFormDifferentialDiagnosisPreview