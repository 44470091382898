import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, MenuItem, TextField } from '@mui/material';

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconAI } from "../../assets/icon-ia.svg"

import { addEvent, dayjs, formattedCurrency, mandatoryField } from '../../utils';
import { getProntuarioPro } from '../../store/actions';
import { getInstallmentsOfProductYearly, getInstallmentsOfProntuarioProYearly } from './Payment';
import { PLAN_MONTHLY, PLAN_YEARLY, PRONTUARIO_AI, PRONTUARIO_AI_MONTHLY, PRONTUARIO_AI_YEARLY_12X, PRONTUARIO_AI_YEARLY_FROM, PRONTUARIO_AI_YEARLY_TO, PRONTUARIO_PRO, PRONTUARIO_PRO_MONTHLY, PRONTUARIO_PRO_YEARLY_12X, PRONTUARIO_PRO_YEARLY_FROM, PRONTUARIO_PRO_YEARLY_TO } from '../../constants';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import PaymentCardField from '../../components/paymentCardField/PaymentCardField';

import './ProPaymentForm.scss'
const ProPaymentForm = (props) => {

    const {
        features,
        clinic,
        history
    } = props

    const PaymentCardFieldRef = useRef()

    const subscription = features?.subscription

    const [ currentPlan, setCurrentPlan ] = useState(PLAN_YEARLY)
    const [ currentProduct, setCurrentProduct ] = useState(PRONTUARIO_PRO)

    const [ plan, setPlan ] = useState(currentPlan)
    const [ errorPlan, setErrorPlan ] = useState(false)
    const [ helperPlan, setHelperPlan ] = useState("")
    const [ product, setProduct ] = useState(currentProduct)
    const [ accordions, setAccordions ] = useState({})

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ installments, setInstallments ] = useState("1")
    const [ errorInstallments, setErrorInstallments ] = useState(false)
    const [ helperInstallments, setHelperInstallments ] = useState(mandatoryField)

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (saveLoading) {
            isValid = false
        }

        if(PaymentCardFieldRef?.current?.validate) {
            isValid = PaymentCardFieldRef?.current?.validate()
        }

        if (plan == currentPlan) {
            isValid = false
            setErrorPlan(true)
            setHelperPlan("Não houve alteração na assinatura")
        } else {
            setErrorPlan(false)
            setHelperPlan()
        }

        if (parseInt(installments) >= 1 && parseInt(installments) <= 12) {
            setErrorInstallments(false)
            setHelperInstallments(mandatoryField)
        } else {
            isValid = false
            setErrorInstallments(true)
            setHelperInstallments("A quantidade de parcelas é obrigatória")
        }

        return isValid
    }

    const isValid = () => PaymentCardFieldRef?.current?.isValid() && !errorPlan && !errorInstallments

    const fieldsWithErrorText = () => {
        const errors = PaymentCardFieldRef?.current?.fieldsWithErrorText() || []
        if (saveLoading) {
            errors.push("Salvando a contratação")
        }
        if (errorPlan) {
            errors.push("Plano selecionado")
        }
        if (errorInstallments) {
            errors.push("Quantidade de parcelas")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate() && PaymentCardFieldRef?.current?.validate()) {
                if (!saveLoading) {
                    setSaveLoading(true)
                    const {
                        payment,
                        isEditField,
                        cardData
                    } = PaymentCardFieldRef.current
                    Parse.Cloud.run("payment", {
                        clinicId: clinic.objectId || clinic.id,
                        plan: plan,
                        payment: {
                            vindiId: isEditField ? null : payment?.id,
                            type: "credit_card",
                            brand: cardData?.brand,
                            number: cardData?.cardNumber,
                            name: cardData?.cardName,
                            expires: cardData?.cardExpires,
                            cvv: cardData?.cardCVC,
                            installments: installments
                        }
                    })
                    .then(_subscription => {
                        props.dispatch(getProntuarioPro(_subscription))
                        addEvent("Subscription__getList", {})
                        setSaveLoading(false)
                        return resolve(clinic)
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: 'Desculpe',
                            text: error.code == 141 ? error.message : 'Não foi possível efetuar o pagamento, por favor, tente novamente',
                            footer: '<a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Fale conosco</a>'
                        })
                        return reject()
                    })
                }
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        installments, plan,
        PaymentCardFieldRef?.current?.isEditField,
        PaymentCardFieldRef?.current?.cardData?.brand,
        PaymentCardFieldRef?.current?.cardData?.cardNumber,
        PaymentCardFieldRef?.current?.cardData?.cardName,
        PaymentCardFieldRef?.current?.cardData?.cardExpires,
        PaymentCardFieldRef?.current?.cardData?.cardCVC
    ])

    useEffect(() => {
        if (features) {
            setCurrentPlan(features.planCode)
            setPlan(features.planCode)
            setCurrentProduct(features.pro ? PRONTUARIO_PRO : PRONTUARIO_AI)
            setProduct(features.pro ? PRONTUARIO_PRO : PRONTUARIO_AI)
        }
    }, [ features ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vspro-payment"
            width="50%"
            title="Editar Assinatura"
            cancel="Cancelar"
            submit={isValid ? "Salvar Alterações" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Edição do Cadastro"
            }}
        >
            <div className="row">
                <div className="col-12">
                    <h3>Assinatura</h3>
                    <div className="current-plan mb-3">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="current">
                                    Plano Atual
                                </div>
                            </div>
                            <div className="col">
                                <div className="name">
                                    { currentProduct == PRONTUARIO_PRO ? (
                                        <>Prontuário PRO</>
                                    ) : (
                                        <>
                                            Prontuário Básico<br/>
                                            + Assistente Vetsmart
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="amount">
                                    valor&nbsp;
                                    { currentProduct == PRONTUARIO_PRO ? (
                                        currentPlan == PLAN_MONTHLY ? (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_PRO_MONTHLY) }</b>/mês
                                            </>
                                        ) : (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_PRO_YEARLY_TO) }</b>/ano
                                            </>
                                        )
                                    ) : (
                                        currentPlan == PLAN_MONTHLY ? (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_AI_MONTHLY) }</b>/mês
                                            </>
                                        ) : (
                                            <>
                                                <b>{ formattedCurrency(PRONTUARIO_AI_YEARLY_TO) }</b>/ano
                                            </>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">Pago { currentPlan == PLAN_MONTHLY ? "mensalmente" : "anualmente" }, o plano será renovado em { dayjs(subscription?.next_billing_at || subscription?.start_at).format("LL") }</div>
                    <h3>Planos disponíveis</h3>
                    <div className="tabs mb-3">
                        <p className="plan text-center">
                            <b>Ganhe 20% de desconto</b> pagando anualmente
                        </p>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col pe-1">
                                        <div className={`col tab ${plan == PLAN_MONTHLY ? "tab-selected" : ""}`} onClick={() => setPlan(PLAN_MONTHLY)}>
                                            Pago mensalmente
                                        </div>
                                    </div>
                                    <div className="col ps-1">
                                        <div className={`col tab ${plan == PLAN_YEARLY ? "tab-selected" : ""}`} onClick={() => setPlan(PLAN_YEARLY)}>
                                            Pago anualmente
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { errorPlan && (
                        <p className="text-danger text-center">
                            { helperPlan }
                        </p>
                    )}
                    <div id="products" className="row g-3 mb-4">
                        <div className="col-12 col-lg">
                            <div className={`card h-100 ${product == "prontuario_ai" ? (plan == currentPlan ? "card-current" : "card-new") : "card-soon"}`}>
                                <div className="card-body">
                                    <h4>
                                        Prontuário Básico<br/>
                                        + Assistente Vetsmart <IconAI width={24} height={24} fill="var(--vsprontuario-warning-color)" />
                                    </h4>
                                    <Accordion
                                        expanded={accordions?.assistant || false}
                                        onChange={() => setAccordions({
                                            ...accordions,
                                            assistant: !accordions?.assistant
                                        })}
                                    >
                                        <AccordionSummary
                                            expandIcon={<IconArrowLeft width={8} height={12} fill="var(--vsprontuario-secondary-color-dark)" style={{ transform: `rotate(${ accordions?.assistant ? "90deg" : "180deg" })` }} />}
                                        >
                                            { accordions?.assistant ? "Ocultar detalhes" : "Ver mais detalhes" }
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Até 1 usuário</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Todas as funcionalidades básicas de atendimento</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Lembretes de vacinas com limitação</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Compartilhamento de documentos digitais com limitação de uso</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Resumo do histórico clínico e diagnóstico diferencial</div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="card-footer">
                                    <div className="row g-3 mb-2">
                                        <div className="col-12">
                                            <div className={`pricing ${plan}`}>
                                                <div className="from">{ plan == PLAN_MONTHLY ? "" : formattedCurrency(PRONTUARIO_AI_YEARLY_FROM) }</div>
                                                <h2 className="amount"><small>{ plan == PLAN_MONTHLY ? "" : "12x de " }R$</small> {formattedCurrency(plan == PLAN_MONTHLY ? PRONTUARIO_AI_MONTHLY : PRONTUARIO_AI_YEARLY_12X)}</h2>
                                                { plan == PLAN_MONTHLY ? "mensal no cartão" : `ou ${formattedCurrency(PRONTUARIO_AI_YEARLY_TO)} à vista`}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="tag">
                                                { product == "prontuario_ai" ? (plan == currentPlan ? "PLANO ATUAL" : "NOVO PLANO") : "EM BREVE" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md">
                            <div className={`card h-100 ${product == "prontuario_pro" ? (plan == currentPlan ? "card-current" : "card-new") : "card-soon"}`}>
                                <div className="card-body">
                                    <h4>Prontuário PRO</h4>
                                    <Accordion
                                        expanded={accordions?.pro || false}
                                        onChange={() => setAccordions({
                                            ...accordions,
                                            pro: !accordions?.pro
                                        })}
                                    >
                                        <AccordionSummary
                                            expandIcon={<IconArrowLeft width={8} height={12} fill="var(--vsprontuario-secondary-color-dark)" style={{ transform: `rotate(${ accordions?.pro ? "90deg" : "180deg" })` }} />}
                                        >
                                            { accordions?.pro ? "Ocultar detalhes" : "Ver mais detalhes" }
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Acessos ilimitados</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Todas as funcionalidades básicas de atendimento</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Recursos avançados de gestão para otimizar a rotina do seu estabelecimento</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Lembretes de vacinas ilimitados</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Compartilhamento de documentos digitais ilimitados</div>
                                            </div>
                                            <div className="row gx-2 mb-2">
                                                <div className="col">Resumo do histórico clínico e diagnóstico diferencial</div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="card-footer">
                                    <div className="row g-3 mb-2">
                                        <div className="col-12">
                                            <div className={`pricing ${plan}`}>
                                                <div className="from">{ plan == PLAN_MONTHLY ? "" : formattedCurrency(PRONTUARIO_PRO_YEARLY_FROM) }</div>
                                                <h2 className="amount"><small>{ plan == PLAN_MONTHLY ? "" : "12x de " }R$</small> {formattedCurrency(plan == PLAN_MONTHLY ? PRONTUARIO_PRO_MONTHLY : PRONTUARIO_PRO_YEARLY_12X)}</h2>
                                                { plan == PLAN_MONTHLY ? "mensal no cartão" : `ou ${formattedCurrency(PRONTUARIO_PRO_YEARLY_TO)} à vista`}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="tag">
                                                { product == "prontuario_pro" ? (plan == currentPlan ? "PLANO ATUAL" : "NOVO PLANO") : "EM BREVE" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vspro-upgrade-subtitle">FORMA DE PAGAMENTO</div>
                    <div className="vspro-upgrade-text mt-1">A cobrança será feita no cartão cadastrado</div>
                    <PaymentCardField
                        ref={PaymentCardFieldRef}
                        clinic={clinic}
                        features={features}
                        subscription={subscription}
                    />
                    { plan == PLAN_YEARLY && (
                        <div className="row g-3 mb-3">
                            <div className="col-12">
                                <TextField
                                    placeholder="Selecione a quantidade de parcelas"
                                    label="Número de Parcelas"
                                    value={installments}
                                    onChange={({ target: { value } }) => setInstallments(value)}
                                    error={errorInstallments}
                                    helperText={helperInstallments}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { getInstallmentsOfProductYearly(product).map(_installment => (
                                        <MenuItem key={_installment.value} value={_installment.value}>
                                            { _installment.label }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        features: state.features
    };
};

export default connect(mapStateToProps)(ProPaymentForm)