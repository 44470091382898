import React, { useEffect, useRef, useState } from "react"
import { useParams } from 'react-router-dom'
import { connect } from "react-redux"
import axios from "axios";

import TextField from '@mui/material/TextField';
import { Alert, AlertTitle } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import { ReactComponent as IconExclamationO } from "../../assets/icon-exclamation-o.svg"

import { addEvent, fillValidEmailOnly, getTrialEndsAtForProduct, redirectToCheckout, saveTrackersProSubscription, stripTags, validateEmail } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import { getClinicUserById } from "./Vet";

import VSDrawer from "../../components/vsDrawer/VSDrawer";
import VSError from "../../components/vsError/VSError";

import "./VetForm.scss"
const VetForm = (props) => {

    const {
        id_usuario
    } = useParams()

    const {
        features,
        user,
        clinic,
        history,
        permissions
    } = props

    const update = id_usuario != "novo"

    const drawerRef = useRef(null)

    const [ error, setError ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ clinicUser, setClinicUser ] = useState(null)
    const [ profile, setProfile ] = useState(null)

    const [ name, setName ] = useState('')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ email, setEmail ] = useState('')
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const validateOnTouch = () => {
        if (name || email) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do médico veterinário é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!validateEmail(email)) {
            isValid = false
            setErrorEmail(true)
            setHelperEmail("O e-mail informado não é válido")
        } else if (email == user.email) {
            isValid = false
            setErrorEmail(true)
            setHelperEmail("Não é possível enviar o convite para o próprio e-mail")
        } else {
            setErrorEmail(false)
            setHelperEmail(<span>Campo <b>Obrigatório</b></span>)
        }

        return isValid
    }

    const isValid = () => !loading && !errorName && !errorEmail && !saveLoading

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorName) {
            errors.push("nome")
        }
        if (errorEmail) {
            errors.push("Email")
        }
        if (loading) {
            errors.push("Buscando informações")
        }
        if (saveLoading) {
            errors.push("Enviando o convite")
        }
        if (!permissions?.check(ACL.ADMIN)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const checkIfInviteAlreadyExists = () => {
        return new Promise((resolve, reject) => {
            if (update) {
                return resolve()
            } else {
                const checkIfInviteAlreadyExistsBeforeSend = new Parse.Query("ClinicUser");
                checkIfInviteAlreadyExistsBeforeSend.equalTo("clinic", clinic.object)
                checkIfInviteAlreadyExistsBeforeSend.equalTo("email", email)
                checkIfInviteAlreadyExistsBeforeSend.notEqualTo("accepted", false)
                checkIfInviteAlreadyExistsBeforeSend.equalTo("isDeleted", false)
                checkIfInviteAlreadyExistsBeforeSend.descending("createdAt")
                checkIfInviteAlreadyExistsBeforeSend.first()
                .then(invited => {
                    if (invited) {
                        return reject(invited.get("accepted") == true ? `Um convite já foi enviado para ${email}, que aceitou o convite.` : `Já enviamos um convite para ${email}, peça para que ele(a) acesse o prontuário para responder o convite.`)
                    } else {
                        return resolve()
                    }
                })
            }
        })
    }

    const sendEmailInviteIfNeeded = () => {
        return new Promise((resolve, reject) => {
            if (update) {
                return resolve()
            } else {
                axios.post(`${process.env.REACT_APP_API_V2}/email/invite`, {
                    "user": user.fullName,
                    "user_email": Parse.User.current().getEmail(), 
                    "clinic": clinic.name, 
                    "name": name,
                    "email": email
                })
                .then(({ data }) => {
                    if (data.result) {
                        return resolve()
                    } else {
                        return reject('O convite foi cadastrado, mas não foi possível enviá-lo por e-mail')
                    }
                })
                .catch(error => {
                    return reject('O convite foi cadastrado, mas não foi possível enviá-lo por e-mail, tente novamente mais tarde')
                })
            }
        })
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (features?.unlimited_users) {
                if (validate()) {
                    setSaveLoading(true)
        
                    return checkIfInviteAlreadyExists()
                    .then(_ => {
                        if (!update) {
                            clinicUser.set("name", name)
                            clinicUser.set("email", email)
                        }
                        // Save an empty permissions and profile because it will be filled by Parse
                        return clinicUser.save()
                        .then(_clinicUser => {
                            return sendEmailInviteIfNeeded()
                            .then(_ => {
                                setSaveLoading(false)
                                addEvent("ClinicUser__getList", {})
                                return resolve(_clinicUser)
                            })
                            .catch(error => {
                                addEvent("ClinicUser__getList", {})
                                console.error(error)
                                Swal.fire(
                                    'Erro ao enviar o e-mail!',
                                    error,
                                    'error'
                                )
                                return reject()
                            })
                        })
                        .catch(error => {
                            setSaveLoading(false)
                            Swal.fire(
                                'Erro ao cadastrar o convite!',
                                error?.code == 141 ? error.message : 'Não foi possível cadastrar o convite, tente novamente mais tarde',
                                'error'
                            )
                            console.error(error)
                            return reject()
                        })
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        setSaveLoading(false)
                        Swal.fire(
                            'Um convite já foi enviado!',
                            error,
                            'error'
                        )
                        return reject()
                    })
                } else {
                    setSaveLoading(false)
                    return reject()
                }
            } else {
                saveTrackersProSubscription(features)
                redirectToCheckout(clinic)
            }
        })
    }

    const getClinicUserOrError = () => {
        setLoading(true)
        setError(null)

        if (update) {
            getClinicUserById(id_usuario, clinic.object, ["profile"])
            .then(_clinicUser => {
                setClinicUser(_clinicUser)
                setName(_clinicUser.get("name"))
                setEmail(_clinicUser.get("email"))
                setLoading(false)
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getClinicUserOrError"
                    })
                })
            })
        } else {
            let newClinicUser = new Parse.Object("ClinicUser")
            newClinicUser.set("clinic", clinic.object)
            newClinicUser.set("createdBy", Parse.User.current())
            newClinicUser.set("createdApp", APP_NAME)
            newClinicUser.set("createdDevice", APP_DEVICE)
            newClinicUser.set("createdAppVersion", APP_VERSION)
            newClinicUser.set("isDeleted", false)
            setClinicUser(newClinicUser)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (clinicUser && !permissions?.check(ACL.ADMIN)) {
            permissions?.error()
        }
    }, [ clinicUser ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getClinicUserOrError()
        }
    }, [ clinic ])

    useEffect(() => {
        validateOnTouch()
    }, [ 
        name, email
    ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vet-form"
            ref={drawerRef}
            width="40%"
            title={update ? "Editar usuário(a)" : "Convidar novo(a) usuário(a)"}
            cancel={features?.unlimited_users && (update ? "Cancelar Alterações" : "Cancelar Convite")}
            submit={features?.unlimited_users ? (isValid ? ( update ? "Salvar Alterações" : "Enviar Convite" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>) : (getTrialEndsAtForProduct(features) ? "Assinar o Prontuário PRO" : "Experimentar 15 dias grátis")}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/gerenciar-acessos")}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Convite enviado com sucesso!" ,
                text: update ? "" : `Um e-mail foi enviado para ${email} com as instruções para finalizar o cadastro.`
            }}
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do(a) usuário(a)? " : "Tem certeza que deseja cancelar o convite?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Usuário(a)",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para o Convite"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getClinicUserOrError":
                                getClinicUserOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : !features?.unlimited_users ? (
                <div className="d-flex h-100">
                    <div className="text-center m-auto">
                        <svg className="mb-4" width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_218_16047" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="170" height="170">
                                <rect width="170" height="170" rx="85" fill="#D8ECFE"/>
                            </mask>
                            <g mask="url(#mask0_218_16047)">
                                <rect width="211.351" height="211.351" transform="matrix(-1 0 0 1 193.148 -18.2988)" fill="#F5F5F5"/>
                                <path d="M78.8984 94.4995L72.2868 92.8889L72.4634 92.1437C73.0815 89.5363 71.3185 87.1377 69.115 86.6005L50.7491 82.1267C48.1776 81.5 45.8122 83.2877 45.2824 85.5221L45.1058 86.2672L38.4943 84.6566C34.0863 83.5831 29.898 86.502 28.9277 90.5988L16.2207 144.233C15.1622 148.702 18.0406 152.949 22.0807 153.933L62.4848 163.776C66.8929 164.85 71.0811 161.931 72.0514 157.834L84.7584 104.2C85.818 99.7314 83.3064 95.574 78.8984 94.4995ZM49.4119 86.1349L68.5116 90.7878L66.8349 97.8641L47.7351 93.2112L49.4119 86.1349ZM68.0113 156.851C67.4815 159.085 65.2927 160.128 63.4561 159.68L23.052 149.837C20.8485 149.3 19.8203 147.081 20.2618 145.218L32.9689 91.5846C33.4986 89.3502 35.6874 88.3077 37.524 88.7553L44.1356 90.366L43.8707 91.4832C43.2526 94.0906 45.0156 96.4892 47.2191 97.0264L65.5851 101.5C68.1565 102.127 70.5219 100.339 71.0517 98.1048L71.3165 96.9876L77.9281 98.5983C80.1316 99.1355 81.1598 101.355 80.7183 103.217L68.0113 156.851Z" fill="#E8E8E8"/>
                                <path d="M66.5082 112.361L37.8575 105.381C36.7558 105.112 36.1975 104.188 36.4624 103.071L36.5507 102.699C36.8156 101.582 37.7271 101.016 38.8288 101.284L67.4794 108.264C68.5812 108.533 69.1395 109.457 68.8746 110.574L68.7863 110.946C68.5214 112.063 67.6099 112.629 66.5082 112.361Z" fill="#E8E8E8"/>
                                <path d="M68.3282 121.078L36.0054 113.204C34.9036 112.935 34.3454 112.011 34.6103 110.893L34.6986 110.521C34.9635 109.404 35.874 108.838 36.9767 109.107L68.9325 116.891C70.0343 117.16 70.5925 118.084 70.3276 119.201L70.2394 119.573C69.9745 120.691 69.063 121.257 68.3282 121.078Z" fill="#E8E8E8"/>
                                <path d="M54.0749 125.485L34.2403 120.653C33.1386 120.384 32.5803 119.46 32.8452 118.343L32.9335 117.971C33.1984 116.854 34.1089 116.288 35.2116 116.556L55.0462 121.388C56.148 121.657 56.7062 122.581 56.4413 123.698L56.353 124.07C56.0881 125.187 55.1767 125.753 54.0749 125.485Z" fill="#E8E8E8"/>
                                <path d="M43.5781 150.11L40.112 141.781L38.4814 143.747L28.9305 141.421L29.8135 137.696L37.1599 139.486L41.3334 134.988L44.4317 143.227L54.5879 131.519L55.4365 144.332L62.0481 145.943L61.166 149.667L51.6162 147.341L51.5583 141.024L43.5781 150.11Z" fill="#E8E8E8"/>
                                <path d="M75.4083 131.951C75.4083 131.951 79.3105 102.973 75.4083 73.3142C71.729 45.3465 73.1419 40.1659 83.0326 29.2418C92.9239 18.3185 130.181 23.2045 134.919 32.6206C139.039 40.8071 154.079 60.5422 155.554 91.7595C157.028 122.977 155.107 125.245 155.107 125.245L75.4083 131.951Z" fill="#081647"/>
                                <path d="M128.352 76.7163C128.352 76.7163 139.007 103.125 131.318 113.012L121.41 103.554L119.145 87.8619L128.352 76.7163Z" fill="#000E2D"/>
                                <path d="M91.0527 83.915C91.0527 83.915 87.2644 114.133 99.5585 119.743L109.005 105.528L105.508 89.9925L91.0527 83.915Z" fill="#000E2D"/>
                                <path d="M99.1973 108.082L103.475 91.7594L124.173 81.7329C124.173 81.7329 123.101 92.926 128.353 108.082H99.1973Z" fill="#AA67B7"/>
                                <path d="M92.0737 110.963L100.546 104.731L131.383 102.817L133.747 106.906L123.365 169.801L130.245 238.466L128.229 238.804C127.211 238.975 126.188 239.107 125.161 239.2L78.6504 243.447L92.0737 110.963Z" fill="#00C4B4"/>
                                <path d="M98.9193 111.644L97.9815 104.036C97.8162 102.696 98.9534 101.499 100.506 101.378L128.429 99.1971C129.186 99.1382 129.843 99.6415 129.866 100.297L130.18 109.349L122.095 121.207L113.783 123.492H103.621L97.7754 118.098L98.9193 111.644Z" fill="#00C4B4"/>
                                <path d="M78.8633 116.535C78.8633 116.535 68.6634 123.168 65.9834 135.146C63.8764 144.562 61.2868 194.518 61.2868 194.518L48 213.401L52.9082 227.051H54.5145C60.167 227.439 65.8636 227.468 70.4505 224.238L74.8915 221.11L83.0332 209.383L78.8633 116.535Z" fill="#D6E5F8"/>
                                <path d="M96.1563 107.752L78.8622 116.535L74.7814 129.556L71.1443 167.294L77.9908 211.537L67.3887 244.265L86.8272 242.783L87.184 227.026L89.2515 156.687L91.2836 111.881L96.1563 107.752Z" fill="#D6E5F8"/>
                                <path d="M155.487 114.379L143 109.426L129.835 101.938L129.752 104.128C131.364 106.808 131.92 109.965 131.318 113.013L120.363 168.496L122.848 209.031L126.702 239.061L160.808 233.348L149.367 207.212L155.786 151.23L155.487 114.379Z" fill="#D6E5F8"/>
                                <path opacity="0.5" d="M120.363 168.495C120.363 168.495 125.3 167.036 131.129 155.156C131.129 155.156 135.689 144.342 133.961 133.876H136.583C136.583 133.876 138.754 118.135 135.019 108.244L128.787 136.958L120.363 168.495Z" fill="#9EC7F3"/>
                                <path d="M158.361 115.459C165.749 118.237 171.229 124.394 172.981 131.894C178.636 156.106 188.318 188.31 188.47 198.7C188.664 211.933 182.703 220.991 174.923 222.662C168.77 223.982 112.997 221.019 112.997 221.019L110.389 200.846L155.552 194.391L149.368 160.843L155.488 114.379L158.361 115.459Z" fill="#D6E5F8"/>
                                <path d="M120.363 168.495C120.363 168.495 137.345 152.586 132.228 132.66L135.417 132.593C135.417 132.593 139.691 104.419 129.852 101.938C129.852 101.938 125.509 146.377 120.363 168.495Z" fill="#FFF5F6"/>
                                <path d="M89.0001 168.69C89.0001 168.69 79.1402 156.71 82.0987 135.174H85.2726L81.5451 130.867C81.5451 130.867 81.6054 121.62 87.5215 111.168C87.5215 111.168 92.1807 108.525 96.1572 107.753C96.1565 107.752 88.8508 135.542 89.0001 168.69Z" fill="#FFF5F6"/>
                                <path d="M127.093 106.202C127.087 106.226 126.679 106.167 125.937 106.105C125.196 106.046 124.118 105.998 122.79 106.062C121.462 106.124 119.889 106.305 118.166 106.609C116.441 106.906 114.566 107.323 112.58 107.72C110.594 108.116 108.673 108.439 106.893 108.551C105.116 108.669 103.49 108.564 102.179 108.289C100.863 108.026 99.8725 107.615 99.2373 107.285C98.9207 107.117 98.6865 106.973 98.5345 106.868C98.3839 106.763 98.309 106.704 98.317 106.696C98.3364 106.675 98.6624 106.886 99.3096 107.186C99.9575 107.481 100.943 107.853 102.238 108.086C103.528 108.328 105.119 108.41 106.866 108.279C108.616 108.153 110.516 107.827 112.495 107.432C114.475 107.037 116.358 106.626 118.099 106.341C119.838 106.05 121.433 105.888 122.778 105.852C124.124 105.814 125.213 105.896 125.955 105.991C126.326 106.041 126.613 106.087 126.804 106.128C126.996 106.167 127.095 106.192 127.093 106.202Z" fill="#00786E"/>
                                <g opacity="0.35">
                                    <g opacity="0.35">
                                        <path opacity="0.35" d="M99.7117 107.377C98.9386 109.122 98.9165 111.285 100.485 112.624C101.765 113.715 103.817 113.987 105.626 113.691C107.434 113.394 109.062 112.613 110.646 111.839C114.622 109.896 118.661 107.884 123.178 107.06C123.467 107.007 123.776 106.952 123.989 106.782C124.202 106.612 124.255 106.283 124.015 106.14C123.908 106.076 123.772 106.062 123.641 106.052C119.646 105.723 115.681 106.65 111.764 107.372C107.847 108.094 103.665 108.605 99.8891 107.487" fill="#00786E"/>
                                    </g>
                                </g>
                                <path d="M105.228 100.98C105.257 101.023 104.666 101.137 104.24 101.756C103.784 102.357 103.809 103.378 103.845 104.481C103.919 106.695 104.364 108.464 104.259 108.482C104.212 108.49 104.042 108.058 103.868 107.338C103.692 106.619 103.526 105.611 103.485 104.49C103.47 103.929 103.463 103.399 103.524 102.904C103.587 102.412 103.757 101.964 104.024 101.645C104.285 101.325 104.601 101.134 104.839 101.049C105.076 100.963 105.224 100.964 105.228 100.98Z" fill="#00786E"/>
                                <path d="M112.86 107.481C112.747 107.472 113.006 105.841 112.653 103.861C112.321 101.878 111.521 100.37 111.627 100.335C111.668 100.32 111.921 100.677 112.218 101.299C112.515 101.918 112.835 102.809 113.011 103.819C113.184 104.829 113.178 105.757 113.103 106.42C113.027 107.086 112.904 107.487 112.86 107.481Z" fill="#00786E"/>
                                <path d="M122.156 99.7091C122.192 99.693 122.455 100.009 122.707 100.589C122.959 101.166 123.159 102.018 123.085 102.961C123.007 103.904 122.671 104.727 122.328 105.272C121.985 105.819 121.673 106.104 121.64 106.084C121.535 106.044 122.597 104.768 122.725 102.942C122.892 101.117 122.045 99.7385 122.156 99.7091Z" fill="#00786E"/>
                                <path d="M90.0997 39.1553C90.0997 39.1553 92.2242 48.7869 106.708 56.1006C121.193 63.4143 120.607 69.1103 120.607 69.1103C120.607 69.1103 123.233 62.676 129.216 65.7295C129.216 65.7295 133.777 69.4489 129.373 76.7158C124.969 83.9827 120.365 83.6159 120.365 83.6159C120.365 83.6159 112.847 95.9997 103.904 96.3217C94.2489 96.6691 87.8434 92.6913 83.2478 80.0972C78.6523 67.5032 79.4133 51.5324 90.0997 39.1553Z" fill="#AA67B7"/>
                                <path d="M90.1914 39.1431L91.2041 41.8338C93.3961 47.6576 97.5326 52.5912 102.994 55.5723C103.188 55.6788 103.386 55.7832 103.586 55.8856C106.039 57.1432 109.224 58.7054 111.615 60.0789C115.235 62.1592 119.563 66.0901 120.604 69.1094C120.604 69.1094 120.615 62.9429 107.979 56.7289C94.3359 50.0203 90.1914 39.1431 90.1914 39.1431Z" fill="#723B7D"/>
                                <path d="M90.1929 39.1431C90.1929 39.1431 82.8818 49.855 80.6623 67.7487C80.6623 67.7487 78.0493 53.2143 90.1929 39.1431Z" fill="#723B7D"/>
                                <path d="M124.172 82.3628C124.172 82.3628 123.119 83.6693 120.363 83.6164C120.363 83.6164 115.741 95.0726 102.272 96.343L101.889 97.8061C101.889 97.8061 114.829 97.4641 120.604 84.6178C120.605 84.6171 123.126 83.9719 124.172 82.3628Z" fill="#723B7D"/>
                                <path opacity="0.25" d="M96.1573 107.752C96.1573 107.752 90.5804 137.586 89.8234 193.95L88.1602 193.849C88.1602 193.849 89.4111 158.006 89.4406 155.412C89.47 152.817 91.7504 118.351 96.1573 107.752Z" fill="#00786E"/>
                            </g>
                            <path d="M36 64C51.464 64 64 51.464 64 36C64 20.536 51.464 8 36 8C20.536 8 8 20.536 8 36C8 51.464 20.536 64 36 64Z" fill="#1993F7"/>
                            <path d="M45.5886 30.3588H44.0765V27.3344C44.0765 23.1607 40.6891 19.7734 36.5155 19.7734C32.3418 19.7734 28.9545 23.1607 28.9545 27.3344V30.3588H27.4424C25.7789 30.3588 24.418 31.7197 24.418 33.3832V48.5051C24.418 50.1685 25.7789 51.5294 27.4424 51.5294H45.5886C47.2521 51.5294 48.613 50.1685 48.613 48.5051V33.3832C48.613 31.7197 47.2521 30.3588 45.5886 30.3588ZM31.9789 27.3344C31.9789 24.8242 34.0053 22.7978 36.5155 22.7978C39.0257 22.7978 41.0521 24.8242 41.0521 27.3344V30.3588H31.9789V27.3344ZM45.5886 48.5051H27.4424V33.3832H45.5886V48.5051ZM36.5155 43.9685C38.1789 43.9685 39.5399 42.6075 39.5399 40.9441C39.5399 39.2807 38.1789 37.9197 36.5155 37.9197C34.8521 37.9197 33.4911 39.2807 33.4911 40.9441C33.4911 42.6075 34.8521 43.9685 36.5155 43.9685Z" fill="white"/>
                        </svg>
                        <h3 className="mb-2">Convide mais usuários para a sua clínica</h3>
                        <p>
                            Assine o Prontuário PRO e convide quem quiser para acessar a sua clínica. 
                            Passe também a gerenciar os acessos, restringindo o que cada um poderá visualizar e além disso, 
                            faça o evento da próxima consulta ou retorno com base no horário de atendimento de cada convidado.
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row align-items-center mb-3">
                        <div className="col">
                            <div className="input-title">Dados Básicos</div>
                        </div>
                    </div>
                    <div className="row align-items-center mb-3">
                        <div className="col-12 mb-3">
                            <TextField
                                placeholder="Nome do(a) usuário(a)"
                                label="Nome do(a) usuário(a)"
                                value={name}
                                onChange={({ target: { value } }) => setName(stripTags(value))}
                                disabled={update}
                                error={errorName}
                                helperText={helperName}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <TextField
                                type="email"
                                placeholder="exemplo@email.com"
                                label="E-mail do(a) usuário(a)"
                                value={email}
                                onChange={({ target: { value } }) => setEmail(fillValidEmailOnly(value))}
                                disabled={update}
                                error={errorEmail}
                                helperText={helperEmail}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 mb-3">
                            <Alert icon={<IconExclamationO width={24} height={24} fill="var(--vsprontuario-warning-color)" stroke="var(--vsprontuario-warning-color)" />} severity="warning">
                                <AlertTitle>Liberação para criação e emissão de documentos médicos</AlertTitle>
                                Este perfil de usuário poderá criar e emitir documentos médicos como atestados, prescrições, solicitações de exames e termos apenas após efetivamente criar sua conta no Prontuário Pro Vetsmart e cadastrar um CRMV válido e ativo
                            </Alert>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VetForm)