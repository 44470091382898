import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconVetsmart } from "../../../../assets/icon-vetsmart.svg"
import { ReactComponent as IconUpload } from "../../../../assets/icon-upload.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../../../assets/icon-virtual-assistant-historic.svg"

import { historyDocument, includeDocuments } from './History'
import { OrderBy_Oldest, OrderBy_Newest } from '../../../../constants'

import VSEmpty from '../../../../components/vsEmpty/VSEmpty'
import VSList from '../../../../components/vsList/VSList'
import VSTimeline from '../../../../components/vsTimeline/VSTimeline'
import { dayjs, saveOnTracker } from '../../../../utils'

import './PatientHistory.scss'
const PatientHistory = (props) => {

    const {
        features,
        patient,
        clinic,
        history
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ histories, setHistories ] = useState({})

    const getIntervalToShow = (allHistoriesByDocumentDate, currentPage) => {
        const documentDates = allHistoriesByDocumentDate.map(date => dayjs(date.get("documentDate")).format("DD/MM/YYYY"))
        const uniqueDocumentDates = [...new Set(documentDates)]
        const totalHistoriesGroupedByDocumentDate = uniqueDocumentDates.length

        const maxIndex = totalHistoriesGroupedByDocumentDate - 1
        const firstIndex = (currentPage - 1) * rowsPerPage
        const lastIndex  = (currentPage * rowsPerPage) - 1

        const firstDocumentDate = uniqueDocumentDates[firstIndex]
        const lastDocumentDate  = uniqueDocumentDates[lastIndex >= maxIndex ? maxIndex : lastIndex]

        const firstDocumentDateMoment = firstDocumentDate ? dayjs(firstDocumentDate, "DD/MM/YYYY") : dayjs()
        const lastDocumentDateMoment  = lastDocumentDate  ? dayjs(lastDocumentDate,  "DD/MM/YYYY") : dayjs()

        // Necessary because orderBy can be descending
        const minInterval = firstDocumentDateMoment.isBefore(lastDocumentDateMoment) ? firstDocumentDateMoment : lastDocumentDateMoment
        const maxInterval = dayjs(firstDocumentDateMoment.isSameOrAfter(lastDocumentDateMoment) ? firstDocumentDateMoment : lastDocumentDateMoment).endOf("day")

        return {
            start: minInterval.toDate(),
            end: maxInterval.toDate(),
            total: totalHistoriesGroupedByDocumentDate
        }
    }
    
    const findHistories = (_, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_History_full",
                filters: {
                    _p_patient: `Patient$${patient.id}`
                },
                dateFrom: dateFrom,
                dateTo: dateTo,
                order: orderBy == OrderBy_Newest ? "newest" : "oldest",
                page: page
            })
            .then(({ total, from, to }) => {
                let queryHistory = new Parse.Query("History");
                queryHistory.equalTo("clinic", clinic.object);
                queryHistory.equalTo("patient", patient);
                queryHistory.greaterThanOrEqualTo("documentDate", from)
                queryHistory.lessThanOrEqualTo("documentDate", to)
                switch (orderBy) {
                    case OrderBy_Oldest:
                        queryHistory.ascending("documentDate");
                        break;
                    default:
                        queryHistory.descending("documentDate");
                }
                queryHistory = includeDocuments(queryHistory)
                queryHistory.find()
                .then(_histories => {
                    let historiesByDate = []
                    let dates = []
                    _histories.map(history => {
                        const documentDateFormatted = dayjs(history.get("documentDate")).format("DD/MM/YYYY")
                        if (!historiesByDate[documentDateFormatted]) {
                            dates.push(documentDateFormatted)
                            historiesByDate[documentDateFormatted] = []
                        }
                        historiesByDate[documentDateFormatted].push(history)
                    })
                    setHistories(historiesByDate)
                    resolve({
                        items: dates,
                        total: total
                    })
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("History__getList", onChange)
        return () => {
            isMounted.current = false
            document.removeEventListener("History__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-history">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: "Histórico de Registros"
                    }}
                    interval={{
                        start: {
                            text: "Início",
                            value: null
                        },
                        end: {
                            text: "Término",
                            value: null
                        }
                    }}
                    customFilter={(
                            <button
                                className="btn-submit btn-square w-100"
                                onClick={() => history.push(`/animal/${patient.id}/historico/compartilhar`)}
                            >
                                <IconUpload width="20px" height="20px" className="me-2" fill="white" />
                                Compartilhar Histórico
                            </button>
                    )}
                    order={{
                        options: [
                            OrderBy_Newest,
                            OrderBy_Oldest
                        ],
                        value: OrderBy_Newest
                    }}
                    pagination={{
                        rowsPerPage: rowsPerPage,
                        customActions: histories && (
                            <button className="col-auto btn-link text-center cursor-pointer me-3" onClick={() => {
                                const trackerSource = "Perfil do Animal"
                                history.push(`/assistente/resumo-historico-clinico/animal/${patient.id}`, {
                                    trackerSource: trackerSource
                                })
                                saveOnTracker("Clicou", "Assistente", "Gerar Resumo", null, trackerSource, null, null, { patientId: patient?.id })
                            }}>
                                <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                Resumir histórico clínico
                            </button>
                        )
                    }}
                    onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                        return findHistories(_searchBy, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item) => {
                        const date = dayjs(item, "DD/MM/YYYY")
                        return (
                            <VSTimeline
                                day={ date.format("DD") }
                                month={ date.format("MMM") }
                                year={ date.format("YYYY") }
                            >
                                { histories && histories[item] && histories[item].map((data, index) => {
                                    const document = historyDocument(data)
                                    if (!document)
                                        return
                                    return <div key={index} className={`vstimeline-item ${data.get("isDeleted") ? "vstimeline-inactive" : "vstimeline-active"}`}>
                                        <document.view object={document.object} history={history} noDocumentDate />
                                    </div>
                                }) }
                            </VSTimeline>
                        )
                    }}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconVetsmart width="32px" height="32px" />}
                            title="Você ainda não possui nenhum registro para este animal"
                            text="Cadastre o primeiro registro sobre o seu paciente utilizando as abas acima"
                        />
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconVetsmart width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca"
                        />
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        features: state.features
    };
};
  
export default connect(mapStateToProps)(PatientHistory)