import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import './Dashboard.scss'
import { CircularProgress } from '@mui/material'
import CardDashboard from '../../components/cardDashboard/CardDashboard';
import CardDashboardAnniversary from '../../components/cardDashboardAnniversary/CardDashboardAnniversary';
import CardDashboardClinicInfo from '../../components/cardDashboardClinicInfo/CardDashboardClinicInfo';
import CardDashboardInvitedVets from '../../components/cardDashboardInvitedVets/CardDashboardInvitedVets';
import CardDashboardFinancialSummary from '../../components/cardDashboardFinancialSummary/CardDashboardFinancialSummary';
import CardDashboardHighlights from '../../components/cardDashboardHighlights/CardDashboardHighlights';
import CardDashboardThreeRows from '../../components/cardDashboardThreeRows/CardDashboardThreeRows';
import CardFeedback from '../../components/cardFeedback/CardFeedback';
import CardProAdvertising from '../../components/cardProAdvertising/CardProAdvertising';

import { ReactComponent as IconDashboardAppointment } from "../../assets/icon-dashboard-appointment.svg"
import { ReactComponent as IconDashboardExams } from "../../assets/icon-dashboard-exams.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconDashboardPatients } from "../../assets/icon-dashboard-patient.svg"
import { ReactComponent as IconDashboardVaccines } from "../../assets/icon-dashboard-vaccines.svg"
import { ReactComponent as IconDashboardServices } from "../../assets/icon-dashboard-services.svg"
import { ReactComponent as IconDashboardPrescriptions } from "../../assets/icon-dashboard-prescriptions.svg"
import { ReactComponent as IconDashboardSupplies } from "../../assets/icon-dashboard-supply.svg"
import { ReactComponent as IconFinancial } from "../../assets/icon-financial.svg"
import { ReactComponent as IconFinancial2 } from "../../assets/icon-financial-2.svg"
import { ReactComponent as IconFinancial3 } from "../../assets/icon-financial-3.svg"
import { ReactComponent as IconSale } from "../../assets/icon-sale.svg"
import { ReactComponent as IconExpense } from "../../assets/icon-expense.svg"
import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"
import { isClinicOwner, formattedCurrency, dayjs, redirectToCheckout, getTrialEndsAtForProduct } from '../../utils';
import { SALE } from '../patient/tabs/sale/Sale';
import { getExpenseICalEvents } from '../expense/Expense';
import { ACL } from '../../constants';

const Dashboard = (props) => {

    const {
        features,
        user,
        history,
        clinic,
        permissions,
        showAsFake
    } = props

    const isCurrentUserClinicOwner = isClinicOwner(clinic, user)

    const [minDate, setMinDate] = useState(dayjs().subtract(30, "days").startOf("day").toDate())
    const [maxDate, setMaxDate] = useState(dayjs().endOf("day").toDate())

    const datesFormatted = dayjs(minDate).format("DD/MM/YYYY") + " - " + dayjs(maxDate).format("DD/MM/YYYY")

    // TODO: Buscar o "Contas a pagar" no Parse, para não precisar calcular os valores internamente
    const [ toPay, setToPay ] = useState(0)

    const [loading, setLoading] = useState(true)
    const [appointment, setAppointment] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })
    const [exams, setExams] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })
    const [patients, setPatients] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })
    const [owners, setOwners] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })
    const [prescriptions, setPrescriptions] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })
    const [services, setServices] = useState({ isLoading: true, countTotal: 0, averageTicket: 0 })
    const [supplies, setSupplies] = useState({ isLoading: true, countTotal: 0, totalStock: 0 })
    const [vaccines, setVaccines] = useState({ isLoading: true, countTotal: 0, countCurrentMonth: 0 })

    const [clinicData, setClinicData] = useState({ isLoading: true, clinicImage: "", clinicName: "", clinicCRMV: "" })
    const [clinicUsers, setClinicUsers] = useState({ isLoading: true, users: [] })
    const [loadClinicUserDetail, setLoadClinicUserDetail] = useState({ userId: null })
    const [estimates, setEstimates] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [receivables, setReceivables] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [payables, setPayables] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [calendars, setCalendars] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [highlights, setHighlights] = useState({ isLoading: true, payments: [], services: [] })
    const [sales, setSales] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [expenses, setExpenses] = useState({ isLoading: true, result: null, currentPage: 0, totalPages: 0 })
    const [clinicAnniversary, setClinicAnniversary] = useState({ displayYearCard: false, displayMonthCard: false, totalMonths: 0, displayYearValue: 0 })
    const [financialSummary, setFinancialSummary] = useState({
        isLoading: true, 
        inflowsAndOutflows: {
            inflows: "R$ 0,00",
            outflows: "R$ 0,00",
            toReceive: "R$ 0,00",
            toPay: formattedCurrency(toPay)
        }
    })

    const getHighlights = () => {
        if(showAsFake){
            setHighlights({
                isLoading: false, 
                payments: [
                    ["Cartão de crédito", "R$ 1.234,99"],
                    ["Cartão de débito", "R$ 444,69"]
                ],
                supplies: [
                    ["Luvas", "23499"],
                    ["Seringas", "44469"]
                ],
                services: [
                    ["Atendimento", "R$ 1.234,99"],
                    ["Castração", "R$ 444,69"]
                ]
            })
            return
        }
        Parse.Cloud.run("getMRDashboardHighlights", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
        })
        .then(result => {
            var labels = {}
            labels["credit"] = "Cartão de crédito"
            labels["debit"] = "Cartão de debito"
            labels["transfer"] = "Pix ou Transferência bancária"
            labels["cash"] = "Dinheiro"
            labels["other"] = "Outros"
            const payments = result.payments.map((row, index) => ({
                name: labels[row.type],
                value: formattedCurrency(row.total)
            }))
            setHighlights({
                isLoading: false, 
                payments: payments,
                supplies: result.supplies || [],
                services: result.services.map((row, index) => ({
                    name: row.serviceName,
                    value: formattedCurrency(row.total)
                }))
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getClinicAnniversary = () => {
        Parse.Cloud.run("getMRDashboardClinicAnniversary", {
            clinicId: clinic.object.id,
        })
        .then(result => {
            setClinicAnniversary({
                displayYearCard: result.displayYearCard,
                displayMonthCard: result.displayMonthCard,
                totalMonths: result.totalMonths,
                displayYearValue: result.displayYearValue,
                dateFormatted: dayjs(result.createdAt).format("DD/MM/YYYY")
            })
        })
        .catch(error => {
            console.error(error)
            setClinicAnniversary({
                displayYearCard: false,
                displayMonthCard: false,
                totalMonths: 0,
                displayYearValue: 0
            })
        })
    }

    const getClinicUserDetails = () => {
        if(showAsFake){
            return
        }
        const index = clinicUsers.users.findIndex(item => item.userId == loadClinicUserDetail.userId)
        if (index != -1 && Object.keys(clinicUsers.users[index].detail).length == 0) {
            Parse.Cloud.run("getMRDashboardClinicUserDetail", {
                clinicId: clinic.object.id,
                userId: loadClinicUserDetail.userId
            })
            .then(_clinicUserDetail => {
                clinicUsers.users[index].detail = _clinicUserDetail
                setClinicUsers({
                    isLoading: false,
                    users: [...clinicUsers.users]
                })
            })
            .catch(error => {
                console.error(error)
            })
        }
    }

    const setupClinicCard = () => {
        setClinicData({
            isLoading: true
        })
        const logo = (clinic.object.get("logo") && clinic.object.get("logo")._url) ? clinic.object.get("logo")._url : ""
        setLoading(false)
        setClinicData({
            isLoading: false,
            clinicImage: logo,
            clinicName: clinic.name,
            clinicCRMV: `${clinic.object.get("crmv") || ""} ${clinic.object.get("crmvStateId") || ""}`
        })
    }

    const getFinancialSummary = () => {
        if(showAsFake){
            setFinancialSummary({
                isLoading: false, 
                inflowsAndOutflows: {
                    inflows: "R$ 0,00",
                    outflows: "R$ 0,00",
                    toReceive: "R$ 21.531,44",
                    toPay: "R$ 21.531,44"
                }
            })
            return
        }
        setFinancialSummary({ isLoading: true })
        Parse.Cloud.run("getMRDashboardFinancialSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
        })
        .then(result => {
            setFinancialSummary({
                isLoading: false, 
                inflowsAndOutflows: {
                    inflows: formattedCurrency(result.inflow),
                    outflows: formattedCurrency(result.outflow),
                    toReceive: formattedCurrency(result.receivable),
                    toPay: formattedCurrency(result.payable),
                }
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const findClinicUsers = () => {
        if(showAsFake){
            setClinicUsers({
                isLoading: false, 
                users: [{
                    name: "Henrique Miedes",
                    crmv: "12345-12"
                }]
            })
            return
        }
        setClinicUsers({
            isLoading: true
        })
        Parse.Cloud.run("getMRDashboardClinicUsers", {
            clinicId: clinic.object.id,
        })
        .then(_clinicUsers => {
            const _users = []
            _clinicUsers.map((clinicUser, index) => {
                if (clinicUser.get("user")?.id) {
                    const _user = clinicUser.get("user")
                    _users.push({
                        userId: _user.id,
                        name: _user.get("fullName"),
                        crmv: _user.get("crmv") || _user?.get("crmvStateId") ? `${_user.get("crmv")} ${_user?.get("crmvStateId")}` : "Não informado",
                        isOpen: false,
                        detail: {}
                    })
                }
            })
            setClinicUsers({
                isLoading: false, 
                users: _users
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getAppointmentInfo = () => {
        if(showAsFake){
            setAppointment({
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setAppointment({ isLoading: true })
        Parse.Cloud.run("getMRDashboardAppointmentSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        }).then(result => {
            setAppointment({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getExamInfo = () => {
        if(showAsFake){
            setExams({ 
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setExams({ isLoading: true })
        Parse.Cloud.run("getMRDashboardExamRequestSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        }).then(result => {
            setExams({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getOwnerInfo = () => {
        if(showAsFake){
            setOwners({ 
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setOwners({ isLoading: true })
        Parse.Cloud.run("getMRDashboardOwnerSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        }).then(result => {
            setOwners({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getPatientInfo = () => {
        if(showAsFake){
            setPatients({ 
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setPatients({ isLoading: true })
        Parse.Cloud.run("getMRDashboardPatientSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        }).then(result => {
            setPatients({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getPrescriptionInfo = () => {
        if(showAsFake){
            setPrescriptions({ 
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setPrescriptions({ isLoading: true })
        Parse.Cloud.run("getMRDashboardPrescriptionSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        }).then(result => {
            setPrescriptions({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getServiceInfo = () => {
        if(showAsFake){
            setServices({ 
                isLoading: false, 
                countTotal: 325800, 
                averageTicket: "R$ 30.258,00"
            })
            return
        }
        setServices({ isLoading: true })
        Parse.Cloud.run("getMRDashboardServiceSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        })
        .then(result => {
            setServices({ isLoading: false, countTotal: result.totalCount, averageTicket: formattedCurrency(result.calculatedAvgTicket) })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getSupplyInfo = () => {
        if(showAsFake){
            setSupplies({ 
                isLoading: false, 
                totalCount: 325800, 
                totalStock: 325800 
            })
            return
        }
        setSupplies({ isLoading: true })
        Parse.Cloud.run("getMRDashboardSupplySummary", {
            clinicId: clinic.object.id
        })
        .then(result => {
            setSupplies({ isLoading: false, totalCount: result.totalCount, totalStock: result.totalStock })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getVaccineInfo = () => {
        if(showAsFake){
            setVaccines({ 
                isLoading: false, 
                countTotal: 325800, 
                countCurrentMonth: 325800 
            })
            return
        }
        setVaccines({ isLoading: true })
        Parse.Cloud.run("getMRDashboardVaccineSummary", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate
        })
        .then(result => {
            setVaccines({ isLoading: false, countTotal: result.countOverall, countCurrentMonth: result.countPeriod })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getEstimates = (page) => {
        if(showAsFake){
            setEstimates({
                isLoading: false, 
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "para Poe, Tutor Henrique Miedes",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "para Belinha, Tutor Vinícius de Andrade",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "para Rex, Tutor Rafaela Moura",
                        row3: "R$ 5.154,74",
                    }
                ], 
                totalPages: 1
            })
            return
        }
        setEstimates({ isLoading: true })
        Parse.Cloud.run("getMRDashboardEstimates", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            setEstimates({
                isLoading: false, currentData: result.currentData.map((row, index) => (
                    {
                        id: row.id,
                        patient: row.get("patient"),
                        owner: row.get("patient").get("owner"),
                        row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY"),
                        row2: `para ${row.get("patient").get("name")}, Tutor ${row.get("patient").get("owner").get("name")}`,
                        row3: formattedCurrency(row.get("finalValue")),
                    }
                )), totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getSales = (page) => {
        if(showAsFake){
            setSales({
                isLoading: false,
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "de Henrique Miedes",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Vinícius de Andrade",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Rafaela Moura",
                        row3: "R$ 5.154,74",
                    }
                ], 
                totalPages: 1
            })
            return
        }
        setSales({ isLoading: true })
        Parse.Cloud.run("getMRDashboardSales", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            setSales({
                isLoading: false,
                currentData: result.currentData.map((row, index) => ({
                    id: row.id,
                    type: row.get("documentType") == SALE ? "venda" : "orcamento",
                    patient: row.get("patient"),
                    owner: row.get("patient").get("owner"),
                    row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY"),
                    row2: "de " + row.get("patient")?.get("owner")?.get("name"),
                    row3: formattedCurrency(row.get("finalValue")),
                })), 
                totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getExpenses = (page) => {
        if(showAsFake){
            setExpenses({
                isLoading: false, 
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "de Aluguel da clínica",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Aluguel da clínica",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Aluguel da clínica",
                        row3: "R$ 5.154,74",
                    }
                ], 
                totalPages: 1
            })
            return
        }
        setExpenses({ isLoading: true })

        Parse.Cloud.run("getMRDashboardExpenses", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            setExpenses({
                isLoading: false, 
                currentData: result.currentData.map((row, index) => ({
                    id: row.id,
                    row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY"),
                    row2: "de " + row.get("name"),
                    row3: formattedCurrency(row.get("value")),
                })), 
                totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getReceivables = (page) => {
        if(showAsFake){
            setReceivables({
                isLoading: false,
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "de Henrique Miedes",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Vinícius de Andrade",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Rafaela Moura",
                        row3: "R$ 5.154,74",
                    }
                ],
                totalPages: 1
            })
            return
        }
        setReceivables({ isLoading: true })
        Parse.Cloud.run("getMRDashboardReceivables", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            setReceivables({
                isLoading: false,
                currentData: result.currentData.map((row, index) => ({
                    id: row.id,
                    type: row.get("documentType") == SALE ? "venda" : "orcamento",
                    patient: row.get("patient"),
                    owner: row.get("patient").get("owner"),
                    row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY"),
                    row2: "de " + row.get("patient")?.get("owner")?.get("name"),
                    row3: formattedCurrency(row.get("paymentsPending")),
                })),
                totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getPayables = (page) => {
        if(showAsFake){
            setPayables({
                isLoading: false,
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "de Henrique Miedes",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Vinícius de Andrade",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "de Rafaela Moura",
                        row3: "R$ 5.154,74",
                    }
                ],
                totalPages: 1
            })
            return
        }
        setPayables({ isLoading: true })

        // const rowsPerPage = 5

        // const query = new Parse.Query("MRExpense")
        // query.equalTo("clinic", clinic.object);
        // query.equalTo("isDeleted", false);
        // query.greaterThan("paymentsPending", 0)
        // query.ascending("documentDate")
        // query.skip(page * rowsPerPage)
        // query.limit(rowsPerPage)
        // query.withCount()
        // return query.find()
        Parse.Cloud.run("getMRDashboardPayables", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            const _toPay = result.currentData.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.get('value'));
            }, 0);
            setToPay(_toPay)

            setPayables({
                isLoading: false,
                currentData: result.currentData.map((row, index) => ({
                    id: row.id,
                    index: index,
                    recurrence: row.get("recurrence"),
                    documentDate: row.get("documentDate"),
                    name: row.get("name"),
                    value: row.get("value"),
                    notes: row.get("notes"),
                    row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY"),
                    row2: "de " + row.get("name"),
                    row3: formattedCurrency(row.get("value")),
                })),
                totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getCalendars = (page) => {
        if(showAsFake){
            setCalendars({
                isLoading: false,
                currentData: [
                    {
                        row1: "19/08/2021",
                        row2: "para Poe",
                        row3: "R$ 1.234,99",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "para Belinha",
                        row3: "R$ 544,69",
                    },
                    {
                        row1: "10/06/2021",
                        row2: "para Rex",
                        row3: "R$ 5.154,74",
                    }
                ],
                totalPages: 1
            })
            return
        }
        setCalendars({ isLoading: true })

        Parse.Cloud.run("getMRDashboardCalendar", {
        // Parse.Cloud.run("getMRDashboardReceivables", {
            clinicId: clinic.object.id,
            minDate: minDate,
            maxDate: maxDate,
            page: page,
            perPage: 5
        })
        .then(result => {
            setCalendars({
                isLoading: false,
                currentData: result.currentData.map((row, index) => ({
                    id: row.id,
                    type: "agendamento",
                    patient: row.get("patient"),
                    owner: row.get("patient").get("owner"),
                    row1: dayjs(row.get("documentDate")).format("DD/MM/YYYY [às] HH:mm"),
                    row2: "para " + row.get("patient")?.get("name"),
                    row3: (row.get("services") && row.get("services").length > 0 ? 
                            row.get("services")[0].serviceName : 'Evento') + 
                            (row.get("services") && row.get("services").length > 1 ? 
                            ' +' + (row.get("services").length - 1) : ''),
                })),
                totalPages: result.totalCount
            })
        })
        .catch(error => {
            console.error(error)
        })
    }

    useEffect(() => {
        getClinicUserDetails()
    }, [ loadClinicUserDetail ])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.CONTROL_PANEL, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        setLoading(true)
        if (clinic && clinic.isLoaded && clinic.object) {
            if(!showAsFake){
                getClinicAnniversary()
            }
            setupClinicCard()
            findClinicUsers()
            getAppointmentInfo()
            getExamInfo()
            getOwnerInfo()
            getPatientInfo()
            getPrescriptionInfo()
            getServiceInfo()
            getSupplyInfo()
            getVaccineInfo()
            getFinancialSummary()
            getEstimates(0)
            getSales(0)
            getExpenses(0)
            getReceivables(0)
            getPayables(0)
            getCalendars(0)
            getHighlights()
        }
    }, [ clinic, features ])

    return (
        <div id="dashboard" className={showAsFake ? "upgrade-to-pro-dashboard" : ''}>
            {loading ? (
                <div className="data-loading">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className={`page-content ${showAsFake && !clinic.isOwner ? 'pb-0' : ''}`}>
                                <div className="page-title">
                                    Painel de Controle
                                </div>
                                {showAsFake && !clinic.isOwner &&
                                <div className="contact-to-owner-advice mt-3">
                                    Entre em contato com o <b>Dono da Clínica</b> para contratar os serviços do Prontuário PRO.
                                </div>}
                            </div>
                        </div>
                        <div className={`page-content row g-3 mb-3 ${showAsFake && !clinic.isOwner ? 'pt-0' : ''}`}>
                            <div className="col-12 col-lg-6">
                                <div className="row g-3 align-items-center">
                                    <div className="col-12">
                                        <CardDashboardClinicInfo
                                            history={history}
                                            isLoading={clinicData.isLoading}
                                            isCurrentUserClinicOwner={isCurrentUserClinicOwner}
                                            clinicImage={clinicData.clinicImage}
                                            clinicName={clinicData.clinicName}
                                            clinicCRMV={clinicData.clinicCRMV}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <CardDashboardInvitedVets
                                            history={history}
                                            isLoading={clinicUsers.isLoading}
                                            isCurrentUserClinicOwner={isCurrentUserClinicOwner}
                                            loadClinicUserDetail={setLoadClinicUserDetail}
                                            vets={clinicUsers.users}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <CardDashboardFinancialSummary
                                            history={history}
                                            isLoading={financialSummary.isLoading}
                                            inflowsAndOutflows={financialSummary.inflowsAndOutflows}
                                            datesFormatted={datesFormatted}
                                        />
                                    </div>
                                    {showAsFake && clinic.isOwner &&
                                    <div className="col-12 d-lg-none">
                                        <CardProAdvertising 
                                            clinic={clinic} 
                                            features={features} 
                                            history={history} 
                                            big 
                                        />
                                    </div>}
                                    <div className="card-dashboard-sales col-12">
                                        <CardDashboardThreeRows
                                            title="Últimas Vendas"
                                            headerIcon={<IconFinancial width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Data"}
                                            // tableHead2={"Tutor"}
                                            // tableHead3={""}
                                            colorValue={"#6AA951"}
                                            isLoading={sales.isLoading}
                                            currentData={sales.currentData}
                                            totalPages={sales.totalPages}
                                            changePage={getSales}
                                            onItemClick={(sale) => history.push(`/${sale.type}/${sale.patient.id}/${sale.id}`)}
                                            datesFormatted={datesFormatted}
                                            history={history}
                                            footer={(
                                                <div className="col">
                                                    <div onClick={() => history.push('/financeiro/entrada')} className="btn-outline-submit btn-square cursor-pointer">
                                                        Ver todas as Vendas
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="card-dashboard-expenses col-12">
                                        <CardDashboardThreeRows
                                            title="Últimas Despesas"
                                            headerIcon={<IconExpense width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Data"}
                                            // tableHead2={"Nome da Despesa"}
                                            // tableHead3={""}
                                            colorValue={"var(--vsprontuario-negative-color)"}
                                            isLoading={expenses.isLoading}
                                            currentData={expenses.currentData}
                                            totalPages={expenses.totalPages}
                                            changePage={getExpenses}
                                            onItemClick={(expense) => history.push(`/despesa/${expense.id}`)}
                                            datesFormatted={datesFormatted}
                                            history={history}
                                            footer={(
                                                <div className="col">
                                                    <div onClick={() => history.push('/financeiro/saida')} className="btn-outline-submit btn-square cursor-pointer">
                                                        Ver todas as Despesas
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="card-dashboard-receivables col-12">
                                        <CardDashboardThreeRows
                                            title="Contas a Receber"
                                            headerIcon={<IconFinancial2 width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Data"}
                                            // tableHead2={"Tutor"}
                                            // tableHead3={""}
                                            isLoading={receivables.isLoading}
                                            currentData={receivables.currentData}
                                            totalPages={receivables.totalPages}
                                            changePage={getReceivables}
                                            onItemClick={(sale) => history.push(`/${sale.type}/${sale.patient.id}/${sale.id}`)}
                                            datesFormatted={datesFormatted}
                                            history={history}
                                            footer={(
                                                <div className="col">
                                                    <div onClick={() => history.push('/financeiro/receber')} className="btn-outline-submit btn-square cursor-pointer">
                                                        Ver Contas a Receber
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="card-dashboard-payables col-12">
                                        <CardDashboardThreeRows
                                            title="Contas a Pagar"
                                            headerIcon={<IconFinancial3 width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Data"}
                                            // tableHead2={"Nome da Despesa"}
                                            // tableHead3={""}
                                            isLoading={payables.isLoading}
                                            currentData={payables.currentData}
                                            totalPages={payables.totalPages}
                                            changePage={getPayables}
                                            onItemClick={(expense) => history.push({
                                                pathname: `/despesa/${ expense.id }${ expense.recurrence ? "/recorrente" : "" }`, 
                                                state: {
                                                    index: expense.index,
                                                    documentDate: expense.documentDate,
                                                    name: expense.name,
                                                    value: expense.value,
                                                    notes: expense.notes
                                                }
                                            })}
                                            datesFormatted={datesFormatted}
                                            history={history}
                                            footer={(
                                                <div className="col">
                                                    <div onClick={() => history.push('/financeiro/pagar')} className="btn-outline-submit btn-square cursor-pointer">
                                                        Ver Contas a Pagar
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="row g-3 align-items-center">
                                    { (clinicAnniversary.displayYearCard || clinicAnniversary.displayMonthCard) && !showAsFake && (
                                        <div className="col-12">
                                            <CardDashboardAnniversary
                                                displayYearCard={clinicAnniversary.displayYearCard}
                                                displayMonthCard={clinicAnniversary.displayMonthCard}
                                                totalMonths={clinicAnniversary.totalMonths}
                                                displayYearValue={clinicAnniversary.displayYearValue}
                                                dateFormatted={clinicAnniversary.dateFormatted}
                                            />
                                        </div>
                                    )}
                                    {showAsFake && clinic.isOwner &&
                                    <div className="col-12 d-none d-lg-block">
                                        <CardProAdvertising 
                                            clinic={clinic} 
                                            features={features} 
                                            history={history} 
                                            big 
                                        />
                                    </div>}
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />}
                                            title="Tutores"
                                            isLoading={owners.isLoading}
                                            labelFirst="Total:"
                                            valueFirst={owners.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={owners.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardPatients fill="#FF8A00" />}
                                            title="Animais"
                                            labelFirst="Total:"
                                            isLoading={patients.isLoading}
                                            valueFirst={patients.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={patients.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardAppointment fill="#1993F7" />}
                                            title="Consultas"
                                            isLoading={appointment.isLoading}
                                            labelFirst="Total:"
                                            valueFirst={appointment.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={appointment.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardVaccines fill="#6AA951" />}
                                            title="Vacinas"
                                            isLoading={vaccines.isLoading}
                                            labelFirst="Total:"
                                            valueFirst={vaccines.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={vaccines.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardExams fill="#CC9E00" />}
                                            title="Exames"
                                            labelFirst="Total:"
                                            isLoading={exams.isLoading}
                                            valueFirst={exams.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={exams.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardPrescriptions fill="#934CA1" />}
                                            title="Prescrições"
                                            labelFirst="Total:"
                                            isLoading={prescriptions.isLoading}
                                            valueFirst={prescriptions.countTotal}
                                            labelSecond="Últimos 30 dias:"
                                            valueSecond={prescriptions.countCurrentMonth} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardServices fill="#FF8F8F" />}
                                            title="Serviços"
                                            isLoading={services.isLoading}
                                            labelFirst="Total de Serviços:"
                                            valueFirst={services.countTotal}
                                            labelSecond="Ticket médio (30 dias):"
                                            valueSecond={services.averageTicket} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <CardDashboard
                                            icon={<IconDashboardSupplies fill="#CC6E00" />}
                                            title="Insumos"
                                            isLoading={supplies.isLoading}
                                            labelFirst="Total de Insumos:"
                                            valueFirst={supplies.totalCount}
                                            labelSecond="Total em estoque:"
                                            valueSecond={supplies.totalStock}
                                        />
                                    </div>
                                    <div className={`col-12 ${getTrialEndsAtForProduct(features) ? "pro-with-trial" : "pro-without-trial"}`} onClick={() => {
                                        if(showAsFake){
                                            redirectToCheckout(clinic)
                                        }
                                    }}>
                                        <CardDashboardHighlights
                                            datesFormatted={datesFormatted}
                                            services={highlights.services}
                                            payments={highlights.payments}
                                            supplies={highlights.supplies}
                                            isLoading={highlights.isLoading}
                                        />
                                    </div>
                                    <div className="card-dashboard-estimate col-12">
                                        <CardDashboardThreeRows
                                            title="Eventos"
                                            headerIcon={<IconCalendarCheck width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Tutor"}
                                            // tableHead2={"Animal"}
                                            // tableHead3={"Valor"}
                                            isLoading={calendars.isLoading}
                                            currentData={calendars.currentData}
                                            totalPages={calendars.totalPages}
                                            changePage={getCalendars}
                                            datesFormatted={datesFormatted}
                                            onItemClick={(calendar) => history.push(`/agenda/${calendar.id}`, {
                                                id: calendar.patient.id,
                                                // date: calendar.get("startDate"),
                                                redirectTo: history.location?.pathname
                                            })}
                                            history={history}
                                            footer={(
                                                <div className="col">
                                                    <div onClick={() => history.push('/agenda')} className="btn-outline-submit btn-square cursor-pointer">
                                                        Ver Agenda
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="card-dashboard-estimate col-12">
                                        <CardDashboardThreeRows
                                            title="Orçamentos"
                                            headerIcon={<IconSale width="24" height="24" fill="#B3B3B3" />}
                                            // tableHead1={"Tutor"}
                                            // tableHead2={"Animal"}
                                            // tableHead3={"Valor"}
                                            isLoading={estimates.isLoading}
                                            currentData={estimates.currentData}
                                            totalPages={estimates.totalPages}
                                            changePage={getEstimates}
                                            datesFormatted={datesFormatted}
                                            onItemClick={(estimate) => history.push(`/orcamento/${estimate.patient.id}/${estimate.id}`, { redirectTo: history.location?.pathname })}
                                            history={history}
                                            // footer={(
                                            //     <div className="col">
                                            //         <div onClick={() => history.push('/financeiro/pagar')} className="btn-outline-submit btn-square cursor-pointer">
                                            //             Ver Orçamentos
                                            //         </div>
                                            //     </div>
                                            // )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!showAsFake &&
                    <CardFeedback contentDetail={history.location?.pathname || "Dashboard"} />}
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};
export default connect(mapStateToProps)(Dashboard);