import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './ClinicSettingStoreInfo.scss'
import VSAccordionSetting from "../../VSAccordionSetting";
import Switch from '@mui/material/Switch';
import { useDidMountEffect, saveOnTracker, setClinic, addEvent } from '../../../../utils';
import { getSelectedClinicUser } from '../../../../store/actions';
import { getClinicUser } from '../../../vet/Vet';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants";
import { CircularProgress } from '@mui/material';

import SettingPreview from '../../SettingPreview'
import { ReactComponent as StoreInfoExample } from "../../../../assets/store-info-example.svg"
import { ReactComponent as IconExternalLink } from "../../../../assets/icon-external-link.svg"
import StoreInfo from '../../../clinic/StoreInfo';
import DisplayStoreField from '../../../clinic/fields/DisplayStoreField';

const ClinicSettingStoreInfo = props => {
    const {
        user,
        clinic,
        permissions
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ isFormChanged, setIsFormChanged ] = useState(false)

    // Opção de Compra
    const [ displayStore, setDisplayStore ] = useState(false)

    const validateOnTouch = () => {
        if(true){
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        return isValid
    }

    const isValid = () => true

    const save = () => {
        if(validate()){
            setLoading(true)
            setIsFormChanged(false)

            const saveUser = Parse.User.current()

            let saveClinic = clinic.object
            saveClinic.set("changedApp", APP_NAME)
            saveClinic.set("changedAppVersion", APP_VERSION)
            saveClinic.set("changedAt", new Date())
            saveClinic.set("changedBy", saveUser)
            saveClinic.set("changedDevice", APP_DEVICE)
            saveClinic.set("displayStore", displayStore);
            saveClinic.save()
            .then(_clinic => {
                afterSave(_clinic)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Oops...',
                    `Não foi possível editar a clínica, por favor, tente novamente`,
                    'error'
                )
                setLoading(false)
                saveClinic.revert()
            })
        }
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            addEvent("TourSettings__onChangeSettings", {})
            setClinic(_clinic)
            props.dispatch(getSelectedClinicUser(clinicUser))
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                error,
                'error'
            )
            setLoading(false)
        })
    }

    // useEffect(() => {
    //     validateOnTouch()
    // }, [
        
    // ])

    useDidMountEffect(() => {
        !isFormChanged &&
        setIsFormChanged(true)
    }, [ 
        displayStore
    ])

    useEffect(() => {
        if (clinic.object) {
            if (!permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)) {
                permissions?.error()
                return
            }

            const selectedClinic = clinic.object
            setDisplayStore(selectedClinic.get("displayStore"))
        }
    }, [ clinic ])

    return (
        <div id="clinic-setting-store-info">
            <div className="row setting-content">
                <div className="col-12 col-md-5 col-xl-4">
                    {clinic.store && !clinic.store.petlove ? (
                        <>
                            <VSAccordionSetting
                                title="Gerenciar Loja">

                                <div className="row">
                                    <div className="col">
                                        <div className="vs-accordion-text-secondary mb-3">Acesse o site <a href="https://minhaloja.petlove.com.br/" target="_blank">minhaloja.petlove.com.br/</a>, clique no botão ”entrar” e faça o login com sua conta Vet Smart para acessar o Administrador da Sua Loja</div>
                                        <a href={clinic?.store?.url}
                                            target="_blank"
                                            className="btn-outline-submit btn-square w-100 d-flex align-items-center justify-content-center">
                                            <IconExternalLink width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />
                                            <span>VISUALIZAR SUA LOJA</span>
                                        </a>
                                    </div>
                                </div>
                            </VSAccordionSetting>
                            <VSAccordionSetting
                                title="Opção de Compra"
                                className="mt-3">

                                { loading ? (
                                    <div className="row loading justify-content-center mt-3">
                                        <div className="col-auto">
                                            <CircularProgress />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <div className="row align-items-center">
                                                <div className="col vs-accordion-inner-title">
                                                    ATIVAR OPÇÃO DE COMPRA
                                                </div>
                                                <div className="col-auto">
                                                    <Switch
                                                        checked={displayStore}
                                                        onChange={({ target: { checked } }) => setDisplayStore(checked)}
                                                        color="primary"
                                                    />
                                                </div>
                                            </div>
                                            <hr className="mb-3 mt-0" />
                                            <div className="vs-accordion-text-secondary mb-3">Ao ativar a função <b>Opção de Compra</b>, você adicionará um link direto para a sua <a href={clinic?.store?.url} target="_blank">Loja Online { clinic?.store?.name }</a> nas Prescrições compartilhadas por SMS, WhatsApp e E-mail</div>
                                            <div className="vs-accordion-text-secondary">Com a <b>Opção de Compra Ativada</b>, todas as versões digitais das prescrições geradas incluirão as informações da disponibilidade de compra.</div>
                                            {false &&
                                            <>
                                                <div className="vs-accordion-text-secondary mb-3">Com a opção <b>Opção de Compra Ativada</b>, todas as versões digitais das prescrições geradas incluirão as informações da disponibilidade de compra como no exemplo abaixo:</div>
                                                <StoreInfoExample />
                                            </>}
                                        </div>
                                    </div>
                                )}
                            </VSAccordionSetting>
                        </>
                    ) : (
                        <VSAccordionSetting
                            title="Opção de Compra">

                            { loading ? (
                                <div className="row loading justify-content-center mt-3">
                                    <div className="col-auto">
                                        <CircularProgress />
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col">
                                        <div className="row align-items-center">
                                            <div className="col vs-accordion-inner-title">
                                                ATIVAR OPÇÃO DE COMPRA
                                            </div>
                                            <div className="col-auto">
                                                <Switch
                                                    checked={displayStore}
                                                    onChange={({ target: { checked } }) => setDisplayStore(checked)}
                                                    color="primary"
                                                />
                                            </div>
                                        </div>
                                        <hr className="mb-3 mt-0" />
                                        <div className="vs-accordion-text-secondary mb-3">Ao ativar a função <b>Opção de Compra</b>, você permitirá que o Vet Smart indique a <a href="https://www.petlove.com.br/" target="_blank">Petlove</a> para que seus clientes adquiram os produtos prescritos através de um link nas Prescrições compartilhadas por SMS, WhatsApp e E-mail</div>
                                        <div className="vs-accordion-text-secondary">Com a <b>Opção de Compra Ativada</b>, todas as versões digitais das prescrições geradas incluirão as informações da disponibilidade de compra.</div>
                                        {false &&
                                        <>
                                            <div className="vs-accordion-text-secondary mb-3">Com a opção <b>Opção de Compra Ativada</b>, todas as versões digitais das prescrições geradas incluirão as informações da disponibilidade de compra como no exemplo abaixo:</div>
                                            <StoreInfoExample />
                                        </>}
                                        {/* <hr className="my-4" />
                                        <div className="vs-accordion-text-secondary mb-3"><b>QUER TER A SUA PRÓPRIA LOJA ONLINE 100% GRATUITA E FAZER UMA RENDA EXTRA?</b> <a href="https://minhaloja.petlove.com.br/" target="_blank">CLIQUE AQUI E SAIBA MAIS</a></div>
                                        <StoreInfo /> */}
                                    </div>
                                </div>
                                /* <div className="vs-accordion-text-primary">
                                    Ao ativar a função Opção de Compra, você permitirá que o Vet Smart indique a Petlove para que seus clientes adquiram os produtos prescritos através de um link nas Prescrições compartilhadas por SMS, WhatsApp e E-mail
                                </div> */
                            )}
                        </VSAccordionSetting>
                    )}
                </div>
                <div className="col-12 col-md-7 col-xl-8">
                    <SettingPreview 
                        isConfigSaved={loading || !isValid() || !isFormChanged}
                        onSave={save}
                        hidePrint
                        />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ClinicSettingStoreInfo)