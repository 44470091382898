import React from 'react'

import { ReactComponent as IconIA } from "../../assets/icon-ia.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"

import { getTrialEndsAtForProduct, redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from '../../utils'

import VSDrawerInfo from '../../components/vsDrawer/VSDrawerInfo';

import './VirtualAssistantDrawerInfoFreePreview.scss'
const VirtualAssistantDrawerInfoFreePreview = (props) => {

    const {
        clinic,
        features,
        history,
        hasDrawerInfoForAI,
        setHasDrawerInfoForAI,
        drawerInfoRef
    } = props
    
    if(!hasDrawerInfoForAI){
        return <></>
    }

    const title = (
        <>
            <IconHeaderIA className="me-0 me-lg-2" width="24px" height="24px" fill="#FF8A00" />
            Assistente Vetsmart
        </>
    )

    const onClose = _ => {
        setHasDrawerInfoForAI(false)
    }

    return (
        <VSDrawerInfo close={title} ref={drawerInfoRef} onClose={() => setHasDrawerInfoForAI(false)}>
            <div className="vsdrawer-info-header d-none d-md-inline">
                {title}
                <button className="vsdrawer-info-close d-none d-md-inline" onClick={onClose}>
                    <IconClose fill="rgba(0, 0, 0, 0.8)" width="20" height="20" />
                </button>
            </div>
            <div id="virtual-assistant-drawer-info-free-preview" className="row flex-grow-1">
                <div className="col-12">
                    <div className="p-3">
                        <div className="text-center mx-auto mb-4">
                            <IconVirtualAssistantSelect className="mb-4" />
                            <h3 className="virtual-assistant-drawer-info-free-preview-title">Assistente Vetsmart</h3>
                            <p className="virtual-assistant-intro-text">{!getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimente" : "Assine"} o Assistente Vetsmart e otimize seu tempo com resumos clínicos e e possibilidades de diagnósticos diferenciais.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <button className="btn-link text-normal text-start d-block no-action">
                                    <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Resumo do histórico clínico
                                </button>
                                <button className="btn-link text-normal text-start d-block no-action">
                                    <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Diagnóstico diferencial
                                </button>
                                <div className="soon-text px-3">
                                    <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                                    Em breve novas funcionalidades
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <button className="btn-submit btn-square text-normal mt-2 w-100 p-0" onClick={() => {
                                    saveTrackersProSubscription(features)
                                    redirectToCheckout(clinic)
                                }}>
                                    { !getTrialEndsAtForProduct(features, 'prontuario_ai') ? "Experimentar o Assistente 15 dias grátis" : "Assinar o Assistente Vetsmart" }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </VSDrawerInfo>
    )
}

export default VirtualAssistantDrawerInfoFreePreview