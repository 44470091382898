import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material'

import { addEvent, getAddressByZipCode, getStateList, mandatoryField, optionalField, replaceAccents, validateCNPJ, validateCPF, validateEmail, validatePhone } from '../../utils';
import { getCustomer } from './Payment';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';

import './ProCustomerForm.scss'
import { getProntuarioPro } from '../../store/actions';
const ProCustomerForm = (props) => {

    const {
        clinic,
        history
    } = props

    const location = useLocation()

    const [ customer, setCustomer ] = useState(null)

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ documentType, setDocumentType ] = useState("PJ")

    const [ name, setName ] = useState("")
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)

    const [ email, setEmail ] = useState("")
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(mandatoryField)

    const [ documentCNPJ, setDocumentCNPJ ] = useState("")
    const [ documentCPF, setDocumentCPF ] = useState("")
    const [ errorDocument, setErrorDocument ] = useState(false)
    const [ helperDocument, setHelperDocument ] = useState(mandatoryField)

    const [ phone, setPhone ] = useState(clinic.object?.get("phone") || "")
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState(mandatoryField)

    const [ notFoundZipCode, setNotFoundZipCode ] = useState(false)
    const [ zipCode, setZipCode ] = useState(clinic.object?.get("zipCode") || "")
    const [ loadingZipCode, setLoadingZipCode ] = useState(false)
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState(mandatoryField)

    const [ addressStreet, setAddressStreet ] = useState(clinic.object?.get("addressStreet") || "")
    const [ errorAddressStreet, setErrorAddressStreet ] = useState(false)
    const [ helperAddressStreet, setHelperAddressStreet ] = useState(mandatoryField)

    const [ addressNumber, setAddressNumber ] = useState(clinic.object?.get("addressNumber") || "")
    const [ errorAddressNumber, setErrorAddressNumber ] = useState(false)
    const [ helperAddressNumber, setHelperAddressNumber ] = useState(mandatoryField)

    const [ addressComplement, setAddressComplement ] = useState(clinic.object?.get("addressComplement") || "")

    const [ neighborhood, setNeighborhood ] = useState(clinic.object?.get("neighborhood") || "")
    const [ errorNeighborhood, setErrorNeighborhood ] = useState(false)
    const [ helperNeighborhood, setHelperNeighborhood ] = useState(mandatoryField)

    const [ city, setCity ] = useState(clinic.object?.get("city") || "")
    const [ errorCity, setErrorCity ] = useState(false)
    const [ helperCity, setHelperCity ] = useState(mandatoryField)

    const [ stateId, setStateId ] = useState(clinic.object?.get("stateId") || "")
    const [ errorStateId, setErrorStateId ] = useState(false)
    const [ helperStateId, setHelperStateId ] = useState(mandatoryField)

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (saveLoading) {
            isValid = false
        }

        if (name.length < 5) {
            isValid = false
            setErrorName(true)
            setHelperName(`O nome do contratante ${name ? "precisa ser maior que 5 caracteres" : "é obrigatório"}`)
        } else {
            setErrorName(false)
            setHelperName(mandatoryField)
        }

        if (!validateEmail(email)) {
            isValid = false
            setErrorEmail(true)
            setHelperEmail(`O e-mail do contratante ${email ? "não é válido" : "é obrigatório"}`)
        } else {
            setErrorEmail(false)
            setHelperEmail(mandatoryField)
        }

        if (!validatePhone(phone)) {
            isValid = false
            setErrorPhone(true)
            setHelperPhone(`O telefone do contratante ${phone ? "não é válido" : "é obrigatório"}`)
        } else {
            setErrorPhone(false)
            setHelperPhone(mandatoryField)
        }

        if (documentType == "PJ" && !validateCNPJ(documentCNPJ)) {
            isValid = false
            setErrorDocument(true)
            setHelperDocument(`O CNPJ do contratante ${documentCNPJ.length ? "não é válido" : "é obrigatório"}`)
        } else if (documentType == "PF" && !validateCPF(documentCPF)) {
            isValid = false
            setErrorDocument(true)
            setHelperDocument(`O CPF do contratante ${documentCPF.length ? "não é válido" : "é obrigatório"}`)
        } else {
            setErrorDocument(false)
            setHelperDocument(mandatoryField)
        }

        if (zipCode.length != 9) {
            isValid = false
            setErrorZipCode(true)
            setHelperZipCode(`O CEP do endereço de cobrança ${zipCode.length ? "não é válido" : "é obrigatório"}`)
        } else {
            setErrorZipCode(false)
            setHelperZipCode(mandatoryField)
        }

        if (addressStreet.length < 3) {
            isValid = false
            setErrorAddressStreet(true)
            setHelperAddressStreet(`O endereço de cobrança ${addressStreet.length ? "não pode ser menor que 3 caracteres" : "é obrigatório"}`)
        } else {
            setErrorAddressStreet(false)
            setHelperAddressStreet(mandatoryField)
        }

        if (!addressNumber) {
            isValid = false
            setErrorAddressNumber(true)
            setHelperAddressNumber(`O número do endereço de cobrança é obrigatório`)
        } else {
            setErrorAddressNumber(false)
            setHelperAddressNumber(mandatoryField)
        }

        if (city.length < 3) {
            isValid = false
            setErrorCity(true)
            setHelperCity(`A cidade do endereço de cobrança ${city.length ? "não pode ser menor que 3 caracteres" : "é obrigatória"}`)
        } else {
            setErrorCity(false)
            setHelperCity(mandatoryField)
        }

        if (neighborhood.length < 3) {
            isValid = false
            setErrorNeighborhood(true)
            setHelperNeighborhood(`O bairro do endereço de cobrança ${neighborhood.length ? "não pode ser menor que 3 caracteres" : "é obrigatório"}`)
        } else {
            setErrorNeighborhood(false)
            setHelperNeighborhood(mandatoryField)
        }

        return isValid
    }

    const isValid = () => !errorName && !errorEmail && !errorPhone && !errorDocument && !errorZipCode && !notFoundZipCode && !errorAddressStreet && !errorAddressNumber && !errorNeighborhood && !errorCity && !errorStateId

    const fieldsWithErrorText = () => {
        let errors = []
        if (saveLoading) {
            errors.push("Salvando a contratação")
        }
        if (errorName) {
            errors.push("Nome do contratante")
        }
        if (errorEmail) {
            errors.push("E-mail do contratante")
        }
        if (errorPhone) {
            errors.push("Telefone do contratante")
        }
        if (errorDocument) {
            errors.push(`${documentType == "PJ" ? "CNPJ" : "CPF"} do contratante`)
        }
        if (errorZipCode) {
            errors.push("Código postal")
        }
        if (notFoundZipCode) {
            errors.push("Código postal não encontrado")
        }
        if (errorAddressStreet) {
            errors.push("Endereço")
        }
        if (errorAddressNumber) {
            errors.push("Número do endereço")
        }
        if (errorNeighborhood) {
            errors.push("Bairro")
        }
        if (errorCity) {
            errors.push("Cidade")
        }
        if (errorStateId) {
            errors.push("Estado do endereço")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate()) {
                if (!saveLoading) {
                    setSaveLoading(true)
                    Parse.Cloud.run("customer", {
                        clinicId: clinic.objectId,
                        customer: {
                            name: name,
                            email: replaceAccents(email),
                            phone: phone.replace(/[^0-9]/g, ""),
                            document: (documentType == "PJ" ? documentCNPJ : documentCPF).replace(/[^0-9]/g, ""),
                            address: {
                                postalCode: zipCode.replace(/[^0-9]/g, ""),
                                street: addressStreet,
                                number: addressNumber,
                                complement: addressComplement,
                                neighborhood: neighborhood,
                                city: city,
                                state: stateId
                            }
                        }
                    })
                    .then(clinic => {
                        const query = new Parse.Query("MSSubscription");
                        query.equalTo("pointer.object", clinic);
                        query.include("products.object");
                        query.include("plan");
                        return query.first()
                        .then(_subscription => {
                            setSaveLoading(false)
                            props.dispatch(getProntuarioPro(_subscription))
                            addEvent("Subscription__getList", {})
        
                            return resolve(clinic)
                        })
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            error.code == 141 ? error.message : 'Não foi possível atualizar o assinante, tente novamente mais tarde',
                            'error'
                        )
                        return reject()
                    })
                }
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ 
        documentType, name, email, phone, documentCPF, documentCNPJ,
        zipCode, addressStreet, addressNumber, neighborhood
    ])

    useEffect(() => {
        setLoadingZipCode(true)
        getAddressByZipCode(zipCode)
        .then(data => {
            if (data.address) {
                setNotFoundZipCode(false)
            } else {
                setNotFoundZipCode(true)
            }
            setLoadingZipCode(false)
            setAddressStreet(data.address)
            setNeighborhood(data.neighborhood)
            setCity(data.city)
            setStateId(data.state_id)
        })
        .catch(error => {
            setLoadingZipCode(false)
            if (error) {
                setNotFoundZipCode(true)
            } else {
                setNotFoundZipCode(false)
            }
        })
    }, [ zipCode ])

    useEffect(() => {
        if (customer) {
            const cnpj = customer?.registry_code?.length == 14
            if (cnpj) {
                setDocumentCNPJ(customer?.registry_code || "")
            } else {
                setDocumentCPF(customer?.registry_code || "")
            }
            setDocumentType(cnpj ? "PJ" : "PF")
            setName(customer?.name || "")
            setEmail(customer?.email || "")
            const customerPhone = customer?.phones[0]
            if (customerPhone) {
                const phone = customerPhone.number?.replace("55", "")
                setPhone(phone || "")
            }

            const address = customer.address
            if (address) {
                setZipCode(address?.zipcode || "")
                setAddressStreet(address?.street || "")
                setAddressNumber(address?.number || "")
                setAddressComplement(address?.additional_details || "")
                setNeighborhood(address?.neighborhood || "")
                setCity(address?.city || "")
                setStateId(address?.state || "")
            }
        }
    }, [ customer ])

    useEffect(() => {
        if (location.state?.customer) {
            setCustomer(location.state.customer)
        } else {
            getCustomer(clinic.objectId)
            .then(_customer => {
                setCustomer(_customer)
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    history.push("/configuracao-clinica/plano-e-cobranca")
                })
            })
        }
    }, [ location ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vspro-payment"
            width="60%"
            title="Editar Informações do Assinante"
            cancel="Cancelar"
            submit={isValid ? "Salvar Alterações" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Edição do Cadastro"
            }}
        >
            <div className="row">
                <div className="col">
                    <VSAccordionSimple
                        expanded={true}
                        header={(_) => (
                            "Dados do Contratante"
                        )}
                    >
                        <div className="row g-3 align-items-center">
                            <div className="col-12 mb-2">
                                <div className="btn-tabs">
                                    <div
                                        className={`btn-tab ${documentType == "PJ" ? 'active' : ''}`}
                                        onClick={() => setDocumentType("PJ")}
                                    >
                                        Pessoa Jurídica
                                    </div>
                                    <div
                                        className={`btn-tab ${documentType == "PF" ? 'active' : ''}`}
                                        onClick={() => setDocumentType("PF")}
                                    >
                                        Pessoa Física
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <TextFieldMask
                                    type="tel"
                                    mask={ documentType == "PJ" ? "00.000.000/0000-00" : "000.000.000-00" }
                                    value={documentType == "PJ" ? documentCNPJ : documentCPF}
                                    onAccept={(value) => documentType == "PJ" ? setDocumentCNPJ(value) : setDocumentCPF(value)}
                                    placeholder={ documentType == "PJ" ? "00.000.000/0000-00" : "000.000.000-00" }
                                    label={ documentType == "PJ" ? "CNPJ da Clínica" : "CPF do Profissional" }
                                    error={errorDocument}
                                    helperText={helperDocument}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-7">
                                <TextField
                                    placeholder={ documentType == "PJ" ? `Ex: Clínica da Maria` : `Ex: Maria da Silva` }
                                    label={ documentType == "PJ" ? "Razão Social" : "Nome do Profissional"}
                                    value={name}
                                    onChange={({ target: { value } }) => setName(value)}
                                    error={errorName}
                                    helperText={helperName}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-5">
                                <TextFieldMask
                                    type="tel"
                                    mask={ phone.length > 14 ? "(00) 00000-0000" : "(00) 0000-00000" }
                                    value={phone}
                                    onAccept={(value) => setPhone(value)}
                                    placeholder="(00) 00000-0000"
                                    label={ documentType == "PJ" ? "Telefone da Clínica" : "Telefone do Profissional" }
                                    error={errorPhone}
                                    helperText={helperPhone}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-7">
                                <TextField
                                    type="email"
                                    placeholder="Ex: email@exemplo.com"
                                    label={ documentType == "PJ" ? "E-mail da Clínica" : "E-mail do Profissional"}
                                    value={email}
                                    onChange={({ target: { value } }) => setEmail(value)}
                                    error={errorEmail}
                                    helperText={helperEmail}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col">
                    <VSAccordionSimple
                        expanded={true}
                        header={(_) => (
                            "Endereço de Cobrança"
                        )}
                    >
                        <div className="row g-3">
                            <div className="col-12 col-lg-4">
                                <TextFieldMask
                                    type="tel"
                                    mask="00000-000"
                                    value={zipCode}
                                    onAccept={(value) => setZipCode(value)}
                                    placeholder="00000-000"
                                    label="CEP"
                                    error={errorZipCode || notFoundZipCode}
                                    helperText={!notFoundZipCode && helperZipCode}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                { loadingZipCode && (
                                                    <CircularProgress size={16} />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    placeholder="Ex: Avenida Paulista"
                                    label="Endereço"
                                    value={addressStreet}
                                    onChange={({ target: { value } }) => setAddressStreet(value)}
                                    error={errorAddressStreet || notFoundZipCode}
                                    helperText={!notFoundZipCode && helperAddressStreet}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { !errorZipCode && notFoundZipCode && (
                                <div className="col-12 mt-1">
                                    <small className="text-danger">
                                        Não foi possível encontrar um endereço válido a partir do CEP informado; 
                                        essa informação é obrigatória para a emissão da Nota Fiscal. <a href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/17010537517211-Prontu%C3%A1rio-Pro" target="_blank" className="text-danger">Fale conosco</a> caso tenha alguma dúvida ou problema em relação ao CEP.
                                    </small>
                                </div>
                            )}
                            <div className="col-12 col-lg-4">
                                <TextField
                                    placeholder="Ex: 123"
                                    label="Número"
                                    value={addressNumber}
                                    onChange={({ target: { value } }) => setAddressNumber(value)}
                                    error={errorAddressNumber}
                                    helperText={helperAddressNumber}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-8">
                                <TextField
                                    placeholder="Ex: Torre Oceania, Apartamento 12"
                                    label="Complemento"
                                    value={addressComplement}
                                    onChange={({ target: { value } }) => setAddressComplement(value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-4">
                                <TextField
                                    placeholder="Ex: São Paulo"
                                    label="Estado"
                                    value={stateId}
                                    onChange={({ target: { value } }) => setStateId(value)}
                                    error={errorStateId}
                                    helperText={helperStateId}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { getStateList().map(stateItem => (
                                        <MenuItem key={stateItem.value} value={stateItem.value}>
                                            { stateItem.text }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                            <div className="col-12 col-lg-4">
                                <TextField
                                    placeholder="Ex: São Paulo"
                                    label="Cidade"
                                    value={city}
                                    onChange={({ target: { value } }) => setCity(value)}
                                    error={errorCity}
                                    helperText={helperCity}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-4">
                                <TextField
                                    placeholder="Ex: Jardim das Flores"
                                    label="Bairro"
                                    value={neighborhood}
                                    onChange={({ target: { value } }) => setNeighborhood(value)}
                                    error={errorNeighborhood}
                                    helperText={helperNeighborhood}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        features: state.features
    };
};

export default connect(mapStateToProps)(ProCustomerForm)