import React from "react"
import { connect } from "react-redux"

import { LocalStoragePrefix, addEvent } from "../../utils"
import { deleteClinicUser } from "./Vet"
import { ACL } from "../../constants"

import { ReactComponent as IconSecondaryEdit } from "../../assets/icon-secondary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconTransaction } from "../../assets/icon-transaction.svg"
import { ReactComponent as IconEye } from "../../assets/icon-primary-eye.svg"

import "./VetView.scss"
const VetView = (props) => {

    const {
        history,
        object,
        clinic,
        user,
        permissions,
        features
    } = props
    
    const currentUser = object.get("user")
    const isAccepted = currentUser && object.get("accepted")
    const name = isAccepted ? currentUser.get("fullName") : object.get("name")
    const email = isAccepted && currentUser.get("email") ? currentUser.get("email") : object.get("email")

    const currentUserIsNotOwner = currentUser?.id == user.objectId && !clinic.isOwner
    const currentUserIsNotOwnerButAdmin = features?.unlimited_users && currentUser?.id != clinic?.owner
    const currentUserIsOwner = currentUser?.id == user.objectId && clinic.isOwner
    const currentUserIsOwnerEmployee = currentUser?.id != user.objectId && clinic.isOwner

    return (
        <div className="col-md-12 col-lg-6 col-xl-4">
            <div id="vet-view" className="h-100">
                <div className="row align-items-center vet-main">
                    <div className="col">
                        { currentUser?.id == clinic?.owner ? (
                            <div className="vet-owner">Dono(a) da Clínica</div>
                        ) : object.get("permissions")?.admin ? (
                            <div className="vet-owner">Acesso de Administrador</div>
                        ) : undefined }
                        <h4 className="vet-name">
                            { name }
                        </h4>
                    </div>
                </div>
                <hr className="vet-separator" />
                <div className="row vet-info">
                    <div className="col">
                        <b>Email:</b> { email }<br/>
                        <b>Perfil:</b> { object.get("profile")?.get("name") || "Não informado" }<br/>
                        <b>Status do Convite: </b>
                        <span className={`vet-status ${object.get("accepted") == undefined ? "" : (isAccepted ? "vet-ok" : "vet-not")}`}>
                            { object.get("accepted") == undefined ? "Pendente" : (isAccepted ? "Aceito" : "Recusado") }
                        </span>
                    </div>
                </div>
                { ((permissions?.check(ACL.ADMIN) && (currentUserIsOwner || currentUserIsNotOwnerButAdmin || currentUserIsOwnerEmployee)) || currentUserIsNotOwner) && (
                    <hr className="vet-separator" />
                )}
                { permissions?.check(ACL.ADMIN) && (
                    <>
                        <div className={`row g-3 align-items-center ${currentUserIsNotOwner ? "mb-3" : ""}`}>
                            { currentUserIsOwner ? (
                                <>
                                    { features?.unlimited_users && (
                                        <div className="col-12">
                                            <button className="vsbox-btn vsbox-btn-dark w-100" onClick={() => history.push(`/configuracao-clinica/gerenciar-acessos/${object.id}`)}>
                                                <IconSecondaryEdit width="24" height="24" className="me-2" />
                                                Editar Horários
                                            </button>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <a className="btn-link w-100 d-flex align-items-center justify-content-center text-uppercase" href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/4926456276507" target="_blank">
                                            <IconTransaction fill="var(--vsprontuario-primary-color)" width="24px" height="24px" />
                                            Solicitar Transferência
                                        </a>
                                    </div>
                                    <div className="col-12 mt-0">
                                        <a 
                                            className="btn-link w-100 d-flex align-items-center justify-content-center text-uppercase mt-1" 
                                            href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/4926371691419" 
                                            target="_blank"
                                            style={{ color: "var(--vsprontuario-negative-color)" }}
                                        >
                                            <IconPrimaryTrash width="24px" height="24px" fill="var(--vsprontuario-negative-color)" />
                                            Solicitar Exclusão da Clínica
                                        </a>
                                    </div>
                                </>
                            ) : currentUserIsNotOwnerButAdmin && (
                                <div className="col-12">
                                    <button className="vsbox-btn vsbox-btn-dark w-100" onClick={() => history.push(`/configuracao-clinica/gerenciar-acessos/${object.id}`)}>
                                        <IconSecondaryEdit width="24" height="24" className="me-2" />
                                        Editar Acessos e Horários
                                    </button>
                                </div>
                            )}
                            { currentUserIsOwnerEmployee && (
                                <div className="col-12 mt-0">
                                    <button 
                                        className="btn-link w-100 d-flex align-items-center justify-content-center text-uppercase mt-2" 
                                        onClick={() => deleteClinicUser(object, () => addEvent("ClinicUser__getList", {}))}
                                        style={{ color: 'var(--vsprontuario-warning-color)' }}>
                                        <IconPrimaryTrash width="24" height="24" fill="var(--vsprontuario-warning-color)" className="me-2" />
                                        Remover Acesso { features?.unlimited_users ? "" : "do Usuário" }
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
                { currentUserIsNotOwner && (
                    <div className="row g-3 align-items-center">
                        {features?.unlimited_users && !object.get("permissions")?.admin &&
                        <div className="col-12">
                            <button className="vsbox-btn vsbox-btn-dark w-100" onClick={() => history.push(`/configuracao-clinica/gerenciar-acessos/${object.id}`)}>
                                <IconEye width="24" height="24" className="me-2" />
                                Visualizar horários
                            </button>
                        </div>}
                        <div className="col-12">
                            <button className="vsbox-btn vsbox-btn-alert w-100" onClick={() => deleteClinicUser(object, () => {
                                localStorage.removeItem(`${LocalStoragePrefix()}selectedClinic`);
                                location.href = process.env.REACT_APP_SITE
                            }, true)}>
                                <IconPrimaryTrash width="18" height="18" className="me-2" />
                                Sair da Clínica
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        user: state.user,
        permissions: state.permissions,
        features: state.features
    }
};

export default connect(mapStateToProps)(VetView)