import React, { useEffect, useState } from "react"
import { useParams, Switch, Route } from 'react-router-dom'
import { connect } from "react-redux"

import { saveOnTracker, useQuery } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";

import { CircularProgress } from "@mui/material";
import VSDrawer from "../../components/vsDrawer/VSDrawer";
import { getClinicUser } from "../vet/Vet";
import { getSelectedClinicUser } from "../../store/actions";

import VirtualAssistantClinicalHistorySummary from './assistants/clinicalhistorysummary/VirtualAssistantClinicalHistorySummary'
import VirtualAssistantDifferentialDiagnosis from './assistants/differentialdiagnosis/VirtualAssistantDifferentialDiagnosis'
import VirtualAssistantDictateAnamnesis from './assistants/dictateanamnesis/VirtualAssistantDictateAnamnesis'

import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantAnamnesis } from "../../assets/icon-virtual-assistant-anamnesis.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"
import { ReactComponent as IconIA } from "../../assets/icon-ia.svg"
import { ReactComponent as IconSimpleWarning } from "../../assets/icon-simple-warning.svg"

import './VirtualAssistantSelectForm.scss'
const VirtualAssistantSelectForm = (props) => {
    
    const {
        features,
        clinic,
        history,
        permissions
    } = props

    const {
        assistant
    } = useParams()

    const [ loading, setLoading ] = useState(false)
    const [ errors, setErrors ] = useState([])

    // useEffect(() => {
    //     if (permissions && !permissions.check(ACL.SETTING, ACL.Rule.EDIT)) {
    //         permissions.error()
    //     }
    // }, [ permissions ])

    // useEffect(() => {
    //     if (clinic.object) {
            
    //     }
    // }, [ clinic ])

    return (
        <VSDrawer
            id="virtual-assistant-select-form"
            // width="40%"
            width={assistant ? "80%" : "385px"}
            title={
                <div className="row">
                    <div className="col-12">
                        Assistente Vetsmart
                    </div>
                </div>
            }
            errors={errors}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: !assistant,
                children: !assistant ? (
                    <div className="row">
                        <div className="col-auto">
                            <IconSimpleWarning width="16" height="16" fill="#666666" />
                        </div>
                        <div className="col ps-0">
                            <p className="virtual-assistant-footer-text mb-0">
                                O Assistente Vetsmart é uma ferramenta de suporte para seus atendimentos clínicos e não deve ser usada como diagnóstico definitivo.
                            </p>
                        </div>
                    </div>
                ) : null
            }}
        >
            {!assistant && 
            <>
                <div className="text-center mx-auto mb-4">
                    <IconVirtualAssistantSelect className="mb-4" />
                    <p className="virtual-assistant-intro-text">Agora a Vetsmart possui um assistente digital que pode te ajudar a encontrar diagnósticos e tratamentos para os seus pacientes.</p>
                </div>
                <div className="row">
                    <div className="col">
                        <h3 className="virtual-assistant-select-title">Selecione o que deseja fazer:</h3>
                        <button className="vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start" onClick={() => {
                            history.push(`/assistente/resumo-historico-clinico/animal/selecionar`)
                            saveOnTracker("Clicou", "Assistente", "Resumo do Histórico Clínico")
                        }}>
                            <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            Resumo do histórico clínico
                        </button>
                        <button className="vsbox-btn vsbox-btn-primary w-100 mt-3 text-normal text-start" onClick={() => {
                            history.push(`/assistente/diagnostico-diferencial/animal/selecionar`)
                            saveOnTracker("Clicou", "Assistente", "Diagnóstico Diferencial")
                        }}>
                            <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            Diagnóstico diferencial
                        </button>
                        <div className="soon-text mt-3">
                            <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                            Em breve novas funcionalidades
                        </div>
                    </div>
                </div>
            </>}
            <div className="row">
                <div className="col">
                    <Switch {...props}>
                        <Route exact path="/assistente/resumo-historico-clinico/animal/:id_animal" render={routerProps =>
                            <>
                                <VirtualAssistantClinicalHistorySummary {...props} {...routerProps} setDrawerError={_errors => setErrors(_errors)} />
                            </>
                        } />
                        <Route exact path="/assistente/diagnostico-diferencial/animal/:id_animal" render={routerProps => 
                            <>
                                <VirtualAssistantDifferentialDiagnosis {...props} {...routerProps} setDrawerError={_errors => setErrors(_errors)} />
                            </>
                        } />
                        {/* <Route exact path="/assistente/ditar-anamnese" component={VirtualAssistantDictateAnamnesis} /> */}
                    </Switch>
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        features: state.features,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantSelectForm)