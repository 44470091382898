import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { CircularProgress, Switch } from '@mui/material'

import { saveOnTracker, useQuery, isClinicOwner } from '../../utils';
import { ACL, APP_CONTENT, APP_DEVICE, APP_NAME, APP_VERSION, HOME_PAGE } from '../../constants';

import { ReactComponent as IconExpand } from "../../assets/icon-expand.svg"
import { ReactComponent as IconRetract } from "../../assets/icon-retract.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"
import { ReactComponent as IconVaccine } from "../../assets/icon-patient-vaccine.svg"
import { ReactComponent as IconPrescription } from "../../assets/icon-patient-prescription.svg"
import { ReactComponent as IconAppointment } from "../../assets/icon-patient-appointment.svg"
import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"

import Survey from '../blocked/Survey';
import HomeStore from './HomeStore';
import HomeLastPatientsOwners from './HomeLastPatientsOwners';
import HomeLastPatients from './HomeLastPatients';
import HomeLastOwners from './HomeLastOwners';
import HomeLastDocuments from './HomeLastDocuments';
import HomeBirthdate from './HomeBirthdate';
import HomeVaccineReminders from './HomeVaccineReminders';
import HomeVaccineRemindersLate from './HomeVaccineRemindersLate';
import HomeVaccineRemindersNext from './HomeVaccineRemindersNext';
import HomeReturns from './HomeReturns';
import HomeLimit from './HomeLimit';
import HomeAssistant from './HomeAssistant';
import VirtualAssistantModal, { LocalStorageVirtualAssistantModalKey } from '../virtualAssistant/VirtualAssistantModal';
import ClubeDescontoModal from '../clubedesconto/ClubeDescontoModal';

import './Home.scss'
import HomeResponsive from './HomeResponsive';
import HomeNews from './HomeNews';
import CardViewSwitch from '../../components/cardViewSwitch/CardViewSwitch';
const Home = (props) => {

    const {
        features,
        user,
        clinic,
        history,
        onboarding,
        permissions
    } = props

    const showFutureBanners = useQuery().get('banner') === "ignorar-data-inicio"
    const isCurrentUserClinicOwner = isClinicOwner(clinic, user)

    const [ expand, setExpand ] = useState(false)
    const [ showBanner, setShowBanner ] = useState(false)
    const [ showNews, setShowNews ] = useState(false)
    const [ showAIModal, setShowAIModal ] = useState(false)

    const onBannerLoaded = (event) => {
        if (event.data > 0) {
            setShowBanner(true)
        }
    }

    const getNotificationNews = () => {
        Parse.Cloud.run("getNotifications_v2", {
            type: "publicProntuario",
            channel: "prontuario_news",
            appVersionName: APP_VERSION,
            deviceType: APP_DEVICE,
            appContentType: APP_NAME
        })
        .then(notifications => {
            if(notifications && notifications.length > 0){
                setShowNews(true)
            }
        })
    }

    useEffect(() => {
        saveOnTracker("Acessou", "Home")

        window.addEventListener('message', onBannerLoaded);
        return () => {
            window.removeEventListener('message', onBannerLoaded);
        }
    }, [])

    useEffect(() => {
        if(clinic && clinic.isLoaded && clinic.object){
            getNotificationNews()
        }
    }, [ clinic ])

    useEffect(() => {
        const hasShowModal = localStorage.getItem(LocalStorageVirtualAssistantModalKey)
        setShowAIModal(hasShowModal === 'false' ? false : true)
    }, [])

    const ShowOrHideAll = (
        <CardViewSwitch 
            expand={expand} 
            setExpand={setExpand} 
        />
    )

    return clinic?.object ? (
        <div id="home" className="row page-content">
            <div className="col-12 mb-3">
                <div className="page-title">
                    Início
                </div>
            </div>
            <div className="col-12 mb-3" style={{ display: showBanner ? 'block' : 'none' }}>
                <iframe src={`${process.env.REACT_APP_SITE}/banner?app=${APP_NAME}&appVersion=${APP_VERSION}&device=${APP_DEVICE}&contentApp=${APP_CONTENT}&displayIn=${HOME_PAGE}&hide-streamings&hide-podcasts${showFutureBanners ? "&showDevEnvironment" : ""}`}></iframe>
            </div>
            { !onboarding.object ? (
                <div className="col-12 mb-3 text-center p-4">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Survey />
                    {showNews &&
                    <div className="col-12">
                        <HomeNews history={history} />
                    </div>}
                    {clinic?.object?.get("isClubeDescontoAvailable") && features?.pro && isCurrentUserClinicOwner && (
                        <ClubeDescontoModal history={history} user={user} />
                    )}
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            { permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-primary"
                                        onClick={() => {
                                            history.push(`/tutor/form/novo`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Novo Tutor")
                                        }}
                                    >
                                        <IconUserO width={40} height={40} fill="#FFFFFF" />
                                        Criar Novo Tutor
                                    </div>
                                </div>
                            )}
                            { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-warning"
                                        onClick={() => {
                                            history.push(`/tutor/selecionar/animal/novo`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Novo Animal")
                                        }}
                                    >
                                        <IconPaw width={40} height={40} fill="#FFFFFF" />
                                        Criar Novo Animal
                                    </div>
                                </div>
                            )}
                            { permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-purple"
                                        onClick={() => {
                                            history.push(`/animal/selecionar/prescricao`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Nova Prescrição")
                                        }}
                                    >
                                        <IconPrescription width={40} height={40} fill="#FFFFFF" />
                                        Criar Nova Prescrição
                                    </div>
                                </div>
                            )}
                            { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-green"
                                        onClick={() => {
                                            history.push(`/animal/selecionar/vacina`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Nova Vacina")
                                        }}
                                    >
                                        <IconVaccine width={40} height={40} fill="#FFFFFF" />
                                        Criar Nova Vacina
                                    </div>
                                </div>
                            )}
                            { permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-secondary"
                                        onClick={() => {
                                            history.push(`/animal/selecionar/consulta`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Nova Consulta")
                                        }}
                                    >
                                        <IconAppointment width={40} height={40} fill="#FFFFFF" />
                                        Criar Nova Consulta
                                    </div>
                                </div>
                            )}
                            { permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW) && (
                                <div className="col-6 col-xl-4 ps-2 pe-2 mb-3">
                                    <div
                                        className="btn-card card-light-green pro-only"
                                        onClick={() => {
                                            history.push(features?.calendar && permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) ? 
                                                        `/animal/selecionar/agenda` : `/agenda`)
                                            saveOnTracker("Clicou", "Home Início", "Criar Novo Agendamento")
                                        }}
                                    >
                                        <IconCalendarCheck width={40} height={40} fill="#FFFFFF" />
                                        Criar Novo Evento
                                    </div>
                                </div>
                            )}
                            { !features?.ai && isCurrentUserClinicOwner && 
                            <div className="col-12 d-block d-lg-none">
                                <HomeAssistant {...props} />
                            </div>}
                            { !features?.unlimited_messages && (
                                <div className="col-12 d-block d-lg-none">
                                    <HomeLimit {...props} />
                                </div>
                            )}
                            <HomeResponsive {...props} />
                            {/* { permissions?.check(ACL.SETTING, ACL.Rule.VIEW) && (
                                <HomeStore {...props} />
                            )} */}
                            <div className="col-12 d-block d-md-none mb-3">
                                { ShowOrHideAll }
                            </div>
                            { permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) &&
                              permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
                                <HomeLastPatientsOwners expanded={expand} {...props} />
                            )}
                            {/* { permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
                                <HomeLastPatients expanded={expand} {...props} />
                            )}
                            { permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
                                <HomeLastOwners expanded={expand} {...props} />
                            )} */}
                            { permissions?.check(ACL.HISTORY, ACL.Rule.VIEW) && (
                                <HomeLastDocuments expanded={expand} {...props} />
                            )}
                            { permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
                                <HomeBirthdate expanded={expand} {...props} />
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            { !features?.ai && isCurrentUserClinicOwner && 
                            <div className="col-12 d-none d-lg-block">
                                <HomeAssistant {...props} />
                            </div>}
                            { !features?.unlimited_messages && (
                                <div className="col-12 d-none d-lg-block">
                                    <HomeLimit {...props} />
                                </div>
                            )}
                            <div className="col-12 d-none d-md-block mb-3">
                                { ShowOrHideAll }
                            </div>
                            { permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
                                <>
                                    <HomeVaccineRemindersLate expanded={expand} {...props} />
                                    <HomeVaccineRemindersNext expanded={expand} {...props} />
                                    {/* <HomeVaccineReminders expanded={expand} {...props} /> */}
                                </>
                            )}
                            { permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
                                <HomeReturns expanded={expand} {...props} />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    ) : <></>
}

const mapStateToProps = state => {
    return {
        features: state.features,
        user: state.user,
        clinic: state.clinic,
        onboarding: state.onboarding,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(Home);